import React, { useState, useEffect } from 'react';

import DiffEditor from './DiffEditor';

import {Button, 
        Dialog, 
        DialogActions, 
        DialogContent, 
        DialogTitle, 
        Typography,
        IconButton,
        Grid,
        CircularProgress
         } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import axiosInstance from '../../axiosConfig';
import { useSnackbar } from '../../context/SnackbarContext';
import StudyOptions from '../autocomplete/StudyOptions';
import IndustryOptions from '../autocomplete/IndustryOptions';
import CompanyOptions from '../autocomplete/CompanyOptions';


export default function DifferentiatorModal({ open, onClose, differentiator, study, company, disabled=[], triggerReload, industry }) {
    const [diffText, setDiffText] = useState('');
    const [selectedStudy, setSelectedStudy] = useState(study? study: null);
    const [selectedIndustry, setSelectedIndustry] = useState(industry? industry: null);
    const [selectedCompany, setSelectedCompany] = useState(company? company: null);
    const {openSnackbar} = useSnackbar();
    const [modalLoading, setModalLoading] = useState(false);
    console.log("industy", industry)
    useEffect(() => {
        if (differentiator) {   
            console.log("differentiator found", differentiator)
            setDiffText(differentiator.text);
            setSelectedIndustry(differentiator.industry);
            setSelectedStudy(differentiator.study);
            setSelectedCompany(differentiator.company);
        }
        else {setDiffText(''); 
            setSelectedIndustry(industry? industry: null);
            setSelectedStudy(study?study:null);
            setSelectedCompany(company?company:null);}
    }, [differentiator])
    const handleContentChange = (content) => {
        setDiffText(content);
    }

    const handleSubmit = () => {
        // Update the content with the editor's content
        console.log("submitting differentiator", selectedCompany, selectedStudy, selectedIndustry)
            setModalLoading(true);
            if (differentiator) {
                axiosInstance.put('/differentiators/'+differentiator.id, {text: diffText, industry_id: selectedIndustry? selectedIndustry.id: null})
                .then(response => {
                    onClose();
                    openSnackbar('Differentiator saved', 'success');
                })
                .catch(error => {
                    console.log(error);
                    openSnackbar('Error saving differentiator', 'error');
                })
            }
            else {
                axiosInstance.post('/differentiators', {text: diffText, study_id: selectedStudy.id, company_id: selectedCompany.id, industry_id: selectedIndustry? selectedIndustry.id: null})
                .then(response => {
                    onClose();
                    openSnackbar('Differentiator saved', 'success');
                })
                .catch(error => {
                    console.log(error);
                    onClose();
                    openSnackbar('Error saving differentiator', 'error');
                })
            }
            setModalLoading(false);
            triggerReload();
        }
    

    return (
        <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
            <> 
            <DialogTitle>
                <Typography width={1} textAlign='center' variant='h5' color='primary'> Add a Differentiator</Typography>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
          <CloseIcon />
        </IconButton>
            <DialogContent>
                <Grid sx={{marginTop:3}} container spacing={2}>
                    <Grid item xs={6}>
                        <StudyOptions study={selectedStudy} setStudy={setSelectedStudy}disabled={study}/>
                    </Grid>
                    <Grid  item xs={6}>
                        <CompanyOptions company={company} setCompany={setSelectedCompany} disabled={disabled.includes("Company")}/>
                    </Grid>
                    <Grid item xs={6}>
                        <IndustryOptions industry={selectedIndustry} setIndustry={setSelectedIndustry} disabled={disabled.includes("Industry")}/>
                    </Grid>

                </Grid>
                <DiffEditor onContentChange={handleContentChange} content={diffText}/>
                
                
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
                <Button onClick={handleSubmit} variant='contained'>{modalLoading && <CircularProgress/>} Save</Button>
            </DialogActions>
            </>
        </Dialog>
    )}
