import {useState} from 'react'
import SegmentOptions from "../../autocomplete/SegmentOptions";
import {Grid, Fab} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import ConcentrationsTable from './ConcentrationTable';
import ConcentrationEditor from './ConcentrationEditor';


export default function Concentrations({company, study}) {
    const [segment, setSegment] = useState({name:'Industry', label:'Industry'})
    const [editorOpen, setEditorOpen] = useState(false)
    const [reload, setReload] = useState(false)

    const forceReload = () => {
        setReload(!reload)
    }
    const editorClose = () => {
        setEditorOpen(false)
    }

    return (
        <>
        <Grid container spacing={2} justifyContent={'center'} alignContent={'center'} sx={{marginTop:3}}>
            <Grid item xs={6}>
                <SegmentOptions study={study} segment={segment} setSegment={setSegment}/> 
            </Grid>
           <Grid item xs={12}>
           </Grid>
        </Grid>
        <ConcentrationsTable company={company} study={study} segment={segment} reload={reload}/>
        {editorOpen && segment && 
        <ConcentrationEditor open={editorOpen} onClose={editorClose} company={company} study={study} segment={segment} forceReload={forceReload}/>
}
        <Fab sx={{position:'fixed', bottom:50, right:100}}
                color="primary"
                aria-label="add"
                onClick={() => {setEditorOpen(true)}}>
                <EditIcon />
        </Fab>  
        </>
    )
}