import { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';

import { useUser } from '../context/UserContext'
import { useSnackbar } from '../context/SnackbarContext';

const useAuth = () => {
  const {user, setUser} = useUser();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {openSnackbar} = useSnackbar();

  
  const login = async (username, password) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post('/login', { username, password });
      console.log(response.data)
      setUser(response.data.user);
      setLoading(false);
      console.log('login successful')
      openSnackbar("You've logged in.", 'success');
    } catch (err) {
      setError(err);
      setLoading(false);
      openSnackbar("Login failed.", 'error')
    }
  };

 
  const logout = async () => {
    setLoading(true);
    console.log("logging out")
    try {
      await axiosInstance.post('/logout');
      setUser(null);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const checkAuth = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/@me');
      console.log("checking auth. response:", response)
      setUser(response.data.user);
      setLoading(false);
    } catch (err) {
      setUser(null);
      setLoading(false);
    }
  };


  return {loading, error, login, logout, checkAuth };
};

export default useAuth;