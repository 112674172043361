import { 
    Typography,
    Grid,
    Paper,
    Stack,
    Box,
    Divider,
    Button,
    LinearProgress,
   } from '@mui/material';
  
  import React from 'react';
  
 
  import { Gauge } from '@mui/x-charts/Gauge';

const roleColor = (role) => {
    switch(role) {
      case 'owner':
        return '#b3c9d7f5'
      case 'secondary':
        return '#e1e4eb'
      default:
        return '#e1e4eb'
    }
  }
const studyProgress = (status) => {
      switch(status) {
        case 'started':
          return 5
        case 'modelling':
          return 20
        case 'outreach':
          return 50
        case 'writing':
          return 75
        default: 
          return 10
      }
  
  }
export default function StudyCard({study, handleClick}) {

    return (
        <Grid key={study.id} item sm={9}>
                    <Paper elevation={4} style={{ padding: 16, height:120, display:'flex', alignItems: 'center', backgroundColor: roleColor(study.role) }}>
                        <Box
                        sx={{width:'30%'}}> 
                        <Button onClick={() => handleClick(study)}> <Typography>{study.name}</Typography> </Button>
                        </Box>
                        
                        <Divider orientation={'vertical'} style={{ margin: '0 16px', height: 'auto' }} flexItem/> 
                        <Box
                        sx={{width:'10%'}}
                        display="flex"
                        alignItems="center"
                        >
                        <Stack> 
                            
                            <Gauge width={100} 
                            height={100} 
                            value={study.totals_modelled} 
                            valueMin={0} 
                            valueMax={study.number_of_companies}
                            text= { ({value, valueMax}) => `${value} / ${valueMax}` }
                            sx={{['& .MuiGauge-valueArc']: {fill: '#d69e02'}}}/>
                            <Typography variant="caption"> Raw Model </Typography>

                        </Stack>
                        </Box>
                        <Divider orientation={'vertical'} style={{ margin: '0 16px', height: 'auto' }} flexItem/> 
                        <Box
                        sx={{width:'10%'}}
                        display="flex"
                        alignItems="center"
                        >
                        <Stack> 
                            <Gauge width={100} 
                            height={100} 
                            value={study.totals_override} 
                            valueMin={0} 
                            valueMax={study.number_of_companies}
                            sx={{['& .MuiGauge-valueArc']: {fill: '#396085'}}}
                            text= { ({value, valueMax}) => `${value} / ${valueMax}` }/>
                            <Typography variant="caption"> Override </Typography>

                        </Stack>
                        </Box>
                        <Divider orientation={'vertical'} style={{ margin: '0 16px', height: 'auto' }} flexItem/> 
                        <Box
                        sx={{width:'10%'}}
                        display="flex"
                        alignItems="center"
                        >
                        <Stack> 

                        <Gauge width={100} 
                            height={100} 
                            value={study.totals_supplier_input} 
                            valueMin={0} 
                            valueMax={study.number_of_companies}
                            sx={{['& .MuiGauge-valueArc']: {fill: '#52b202'}}}
                            text= { ({value, valueMax}) => `${value} / ${valueMax}` }/>
                        <Typography variant="caption"> Supplier Input</Typography>
                        </Stack>
                        </Box>
                        
                        <Divider orientation={'vertical'} style={{ margin: '0 16px', height: 'auto' }} flexItem/> 
                        <Box
                        sx={{width:'30%'}}
                        alignItems="center"> 
                        <Stack> 
                            <Typography variant="caption"> Progress</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: '100%', mr: 1 }}>
                                <LinearProgress variant='determinate' value={studyProgress(study.status)} />
                            </Box>
                            <Box sx={{ minWidth: 35 }}>
                                <Typography variant="body2" color="text.secondary">{studyProgress(study.status)}%</Typography>
                            </Box>
                        
                        </Box>
                        </Stack>
                        </Box>
                    </Paper>
        </Grid>
        )

    }