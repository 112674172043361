import axios from 'axios';

console.log("API URL: ", process.env.REACT_APP_API_URL);
console.log("env variables: ", process.env);
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
//const apiUrl = 'https://api.arcinternal.online'

const axiosInstance = axios.create({
  baseURL: apiUrl,
  withCredentials: true
  
});


 
export default axiosInstance;