import React, {useState,useEffect} from 'react'
import { useParams } from 'react-router-dom';
import {useSnackbar} from '../../context/SnackbarContext';
import axiosInstance from '../../axiosConfig';

import {Box,
        Tabs,
        Tab,
        Grid,
        CircularProgress,
        Typography
         } from '@mui/material'


import CompanyAnalysis from '../study/modelling/company_analysis/CompanyAnalysis';
import MarketAnalysis from '../study/modelling/market_analysis/MarketAnalysis';
import StudyLanding from '../study/StudyLanding';

const TabPanel = ({value, study}) => {
    switch(value) {
      case 'one':
        return <StudyLanding study={study}/>
      case 'two':
        return <CompanyAnalysis study={study}/> 
      case 'three':
        return <MarketAnalysis study={study}/>
      case 'four':
        return <>Not Implemented Yet.</>
      default:
        return <div>Overview</div>
    }
  }


export default function StudyPage() {
    const {studyName} = useParams();
    const [loading, setLoading] = useState(false);
    const [study, setStudy] = useState(null)
    const [value, setValue] = useState('one');
    const { openSnackbar } = useSnackbar(); 


    useEffect(() => {
        const fetchStudy = async () => {
            try {
                setLoading(true)
                const response = await axiosInstance.get('/studies/search',{params: {"name":studyName}})
                setStudy(response.data.data)
                setLoading(false)
            } catch (error){
                console.log("error fetching study from url.", error )
                openSnackbar(error.message, 'error')
                setLoading(false)
        }}
        if (!study) {
           fetchStudy()
        }
            
    },[])
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    
    
    if (!study) {
      
      return (
        <Grid container alignItems={'center'} justifyContent={'center'}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', width:1, justifyContent:'center' }}><CircularProgress/></Box>
          </Grid>
        </Grid>

      )
    }
    else {
       
      return (
        <>
        <Grid container alignItems={'center'} justifyContent={'center'}>
          <Grid item xs={12}>
            
              <Typography variant='h4' color='primary' sx={{fontWeight:'bold'}}> {study.name} </Typography>
    
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ width: '100%', justifyContent: 'center', alignItems:'center', display:'flex'}}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="wrapped label tabs example"
              >
                <Tab value="one" label="Home"/>
                <Tab value="two" label="Company Analysis"/>
                <Tab value="three" label="Segment Analysis" />
                <Tab value="five" label="Differentiators" />
              </Tabs>
    
            </Box>
          </Grid>
          <Grid item xs={12}>
          {loading &&  <Box sx={{ display: 'flex', width:1, justifyContent:'center' }}><CircularProgress/></Box>}
          {(!loading) && study && <TabPanel value={value} study={study}/>}
          </Grid>
        </Grid> 
           
        </> )
    }
  }
    