import  React,{useState, useEffect} from 'react'
import {Box, Container, Divider, CircularProgress} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import useAPI from "../../../hooks/useAPI";
import CategoryCompanies from './CategoryCompanies';
import ActionsMenu from './Actions Menu';
import ActionsButton from '../../buttons/ActionsButton';
import formatDecimal from '../../../utils/formatDecimal';

const columnGrouping = [
    {groupId:"2023",
     children: [{field:'revenue23'}, {field:'concentration23'}, {field:'share23'}],
     headerAlign:'center',
     headerClassName: 'year-group'
    },
    {groupId:"2024",
        children: [{field:'revenue24'}, {field:'concentration24'}, {field:'share24'}],
        headerAlign:'center',
        headerClassName: 'year-group'

       },
    {groupId: 'YoY',
        children: [{field:'revenueYoY'}, {field:'shareYoY'}],
        headerAlign:'center',
        headerClassName: 'year-group'
    }
]
const splitData = (data) => {
    const industries = [...new Set(data.map((d) => d.category))]
    const yearOne = data.filter((d) => d.year == 2023)
    const yearTwo = data.filter((d) => d.year == 2024)

    const mapped = industries.map((ind) =>  {
        const first = yearOne.filter((item) => item.category===ind)[0];
        const second = yearTwo.filter((item) => item.category===ind)[0];
            return (
                    {revenue23: first.revenue,
                        share23: first.market_share,
                        concentration23: first.concentration,
                        revenue24: second.revenue,
                        share24: second.market_share,
                        concentration24: second.concentration,
                        shareYoY: ((second.market_share-first.market_share)/first.market_share),
                        revenueYoY: ((second.revenue-first.revenue)/first.revenue),
                        id: second.id,
                        name: ind,
                        other: null
                    }
                    )})
                        
    return ({groups: industries, mapped:mapped})
}
export default function ConcentrationsTable({company, segment, study, reload}) {
    
    const {data, error, loading} = useAPI('concentrations', {params:{study_id:study.id, company_id:company.id, segment:segment.name}}, 'Failed to get Concentrations', [segment, company, reload])
    const [concArray, setConcArray]= useState([]);
    const [open, setOpen] = useState(false);
    const [selectedCategory, setSelectedCateogry] = useState(null)

    const handleClose = () => {
        setOpen(false);
    }
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
  
    const handleMenuOpen = (event, row) => {
      setAnchorEl(event.currentTarget);
      setMenuOpen(true);
      setSelectedCateogry(row)
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
      setMenuOpen(false);
    };
    useEffect(() => {
        if (data) {
            console.log("fetched concentration overview", data)
            const splitD = splitData(data.data)
            console.log("split the data", splitD)
            setConcArray(splitD.mapped)
        }
    }, [data, company, segment])
    const columns = [
        {field:'name',
            headerName:'Category',
            width:200,
            renderCell: (params) => {
                return <span style={{color:'#1f409c', fontWeight:'bold'}}> {params.value} </span>
            }
         },
        {field:'revenue23',
             type:'number', 
             headerName:'Revenue', 
             width:100,
             valueFormatter: (value) => {
                return `$ ${value.toFixed(2)}`}
            },
        {field:'concentration23', headerName:'Concentration', width:100,
            valueFormatter: (value) => {
                return `${(value*100).toFixed(2)} %`}
        },
        {field:'share23', headerName: 'Share', width:100,
            valueFormatter: (value) => {
                return `${(value*100).toFixed(2)} %`}
        },
        {field:'revenue24',
            headerName:'Revenue',
            width:100,
            valueFormatter: (value) => {
                return `$ ${value.toFixed(2)}`}},
        {field:'concentration24', headerName:'Concentration', width:100,
            valueFormatter: (value) => {
                return `${(value*100).toFixed(2)} %`}
        },
        {
            field:'share24', 
            headerName: 'Share',
            width:100,
            renderCell: (params) => (
                <React.Fragment>
                    {formatDecimal(params.value)}
                <Divider orientation='vertical'></Divider>
                </React.Fragment>
            )
        },
        
        {field:'shareYoY', headerName: 'YoY share', width:100,
            valueFormatter: (value) => {
                return `${(value*100).toFixed(2)} %`}
        },
        {field:'revenueYoY', headerName: 'YoY revenue', width:100,
            valueFormatter: (value) => {
                return `${(value*100).toFixed(2)} %`}
        },
        //{field: 'actions', type:'actions', renderCell: (params) => <ConcentrationActions concentration={params.row} category={params.row.name} study={study} buttonType={'slim'}/>}
        {field: 'action', type:'actions', renderCell: (params) =>   
        <>
            <ActionsButton buttonType={'slim'}  handleClick={(event) => handleMenuOpen(event, params.row)}/>
            <ActionsMenu
              key={params.row.name}
              anchorEl={anchorEl}
              open={menuOpen && selectedCategory?.name===params.row.name}
              onClose={handleMenuClose}
              concentration={params.row}
              study={study}
            />
          </>}
    
    ]
    return (
            <Container>
            {loading && 
            <Box sx={{width:1, display:'flex', justifyContent:'center', alignItems:'center', marginBottom:5}}> <CircularProgress/> </Box>
            }
            {concArray && (!loading) && 
                <DataGrid sx={{
                    '& .MuiDataGrid-row:nth-of-type(odd)': {
                      backgroundColor: '#f5f5f5', // Light gray for odd rows
                    },
                    '& .MuiDataGrid-row:nth-of-type(even)': {
                      backgroundColor: '#ffffff', // White for even rows
                    },
                    '& .year-group': {color:'#1f409c', fontSize:'14pt', fontWeight:'bold'}
                  }}
                    rows={concArray} columns={columns} columnGroupingModel={columnGrouping}/>
                    
                    }
            {open && selectedCategory && <CategoryCompanies category={selectedCategory} study={study} open={open} onClose={handleClose}/>}
            
            </Container>
    )
}