import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import axiosInstance from '../../axiosConfig';
import { useSnackbar } from '../../context/SnackbarContext';


export default function ConfirmDelete({open, onClose, differentiator, triggerReload}) {

    const {openSnackbar} = useSnackbar();
    const handleDelete = () => {
        axiosInstance.delete('/differentiators/'+differentiator.id)
        .then(response => {
            onClose();
            openSnackbar('Differentiator deleted', 'success');
        }
        )
        .catch(error => {
            console.log(error);
            openSnackbar('Error deleting differentiator', 'error');
        }
        )
        triggerReload();
    }
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Delete Differentiator?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this differentiator?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleDelete} color="primary" autoFocus>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}