import { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';
import { useSnackbar } from '../context/SnackbarContext';
import { useUser } from '../context/UserContext';

const useAPI = (url, options={}, message='', refresh=[]) => {
    const { openSnackbar } = useSnackbar();
    const { openLoginModal, user } = useUser();
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        let isMounted = true;
        const getData = async () => {
            setLoading(true);
            try {
                let response = await axiosInstance.get(url, options);
                console.log('response', response.data)
                setData(response.data);
                setLoading(false)
                setError(null)

            } catch (error) {
                setError(error);
                if (error.response && error.response.status === 401) {
                    openLoginModal();
                    openSnackbar('Please log in to continue', 'error');
                } else {
                    openSnackbar('An error occurred', 'error');
                }
            } finally {
                setLoading(false);
            }
        };

        getData();
        return () => {
            isMounted = false;
        }
    },[url, user, ...refresh]);

    return { data, error, loading };
};

export default useAPI;