import { useEffect, useState } from "react";
import {Grid, Box, Typography, Container} from '@mui/material'
import useAPI from "../../hooks/useAPI";
import { LineChart } from "@mui/x-charts";

const sortedData = (data) => {

 return data.sort((a, b) => {
    if (a.year !== b.year) {
      return a.year - b.year;
    } else {
      return Number((a.quarter.slice(1,2))) - Number((b.quarter.slice(1,2)));
    }
  });
}

const displayYear = (year) => {
    const yearend = String(year).split(".")[1]
    console.log("year", year)
    console.log("yearend", yearend)
    switch(yearend) {
        case '2': return 'Q1'
        case '4': return 'Q2'
        case '6': return 'Q3'
        case '8': return 'Q4'
        default: return `${year.toFixed(0)}`
    }
}
export default function Financials({company}) {
    const {data, error, loading} = useAPI('financials', {params: {'resource':'company', 'company_id':company.id}})
    const [labels, setLabels] = useState(null)
    const [y, setY] = useState(null)
    const [g, setG] = useState()

    useEffect(() => {
        if (data) {
            const sorted = sortedData(data.data)
            console.log("sorted", sorted)
            setLabels(sorted.map((item) => item.year + Number((item.quarter.slice(1,2))/4)))
            setY(sorted.map((item) => item.revenue))
            //setG(sorted.map((item) => item.yoy_growth))
        }
    },[data])
    useEffect(() => {
        if (data) {
            console.log("y",y)
            console.log("labels", labels)
        }
    },[y, labels])
    return (
        <Container sx={{height:400}}>
        
            {(!loading) && y && labels && 
            <LineChart 
                //xAxis={[{data:labels, valueFormatter: (value) => `${value.toFixed(2)}`}]}
                xAxis={[{data:labels, valueFormatter: (value) => displayYear(value)}]}
                series={[{ data:y, valueFormatter: (value) => `$ ${value}`}]}
                tooltip={{ trigger: 'item' }}
                grid={{ vertical: true, horizontal: true }}
                 />
}
        </Container>
            
    )
}