import React from 'react';
import { Dialog, DialogActions,IconButton, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import axiosInstance from '../../../axiosConfig';
import { useSnackbar } from '../../../context/SnackbarContext';


export default function DeleteCompany({open, onClose, company, study, triggerReload}) {

    const {openSnackbar} = useSnackbar();
    const handleDelete = () => {
        axiosInstance.delete('/studies/'+study.id +'/companies/' +company.id)
        .then(response => {
            onClose();
            openSnackbar('Company deleted', 'success');
        }
        )
        .catch(error => {
            console.log(error);
            openSnackbar('Error deleting differentiator', 'error');
        }
        )
        ;
    }
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Delete {company.name}?</DialogTitle>
            <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <CloseIcon />
                </IconButton>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to remove {company.name} from {study.name}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button variant={'contained'} onClick={handleDelete} color="primary" autoFocus>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}