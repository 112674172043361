
import { Dialog, IconButton, DialogTitle, DialogContent, Typography } from "@mui/material"
import Financials from '../../company/Financials'
import CloseIcon from '@mui/icons-material/Close'

export default function FinancialsModal({open, onClose, company}) {
    return (
        
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                <Typography variant={'h6'} fontWeight='bold' color='primary' textAlign={'center'}> {company.name}'s Publicly Reported Revenue</Typography>
            </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <CloseIcon />
                </IconButton>
            <DialogContent>
                    <Financials company={company}/>
            </DialogContent>
        </Dialog>
    )
}