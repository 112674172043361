import React, { useEffect, useState } from 'react';
import {
  Button,                       
  Grid,
  Dialog,
  Box,
  DialogContent,
  DialogActions,
  DialogTitle,
  CircularProgress,
  Typography,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from '../../../context/SnackbarContext';
import ModellingSlider from './ModellingSlider';
import Financials from '../../company/Financials'
import axiosInstance from '../../../axiosConfig';
import RegionPanel from './ModellingModal/RegionPanel'
import IndustryPanel from './ModellingModal/IndustryPanel';


const DisplayPanel = ({panel, study, company}) => {

    switch(panel) {
        case 'region':
            return <RegionPanel study={study} company={company}/>
        case 'industry':
            return <IndustryPanel study={study} company={company}/>
        case 'company':
            return <Financials company={company}/>
}
}

export default function ModellingModal({open, onClose, study, company, total, forceReload}) {
    const [confirmOpen, setConfirmOpen] = useState(false);
    const { openSnackbar } = useSnackbar();
    const [note, setNote] = useState(total ? total.note : '');
    const [loading, setLoading] = useState(false);
    const [newRevenue, setNewRevenue] = useState(0);
    const [growth, setGrowth] = useState(0);
    const [panel, setPanel] = useState('region');

    const handleChange = (event, value) => {
        setPanel(value)
    }

    useEffect(() => {
        if (total) {
            setNewRevenue(total.revenue)
            const growth = ((total.revenue-total.total_previous_year.revenue)/total.total_previous_year.revenue)*100
            setGrowth(growth)
        }
        }, [company])

    const handleConfirmClose = () => {
        setConfirmOpen(false);
      };
    const handleSaveChanges = () => {
            setConfirmOpen(true)
    } 
    
    const handleSubmit = async () => {
        setLoading(true);
        handleConfirmClose()
        const requestData = {'status':'revised', 'revenue': newRevenue, note: 'note'};
        console.log('pre request', requestData)
        try {
            const response = await axiosInstance.put('company-totals/'+total.id, requestData);
            console.log(response)
            openSnackbar('Total Saved Successfully!')
            forceReload()
        } catch(error){
            openSnackbar('Failed to save', 'error')
        }
        setLoading(false);
        onClose();
    }
    

    return (
        
        
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>
                <Typography variant={'h6'} fontWeight='bold' color='primary' textAlign={'center'}> {total.company_name}</Typography>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
          <CloseIcon />
        </IconButton>
            <DialogContent>
            
            <Grid container spacing={3}>
                <Grid item xs={4}/>
                        <Grid item xs={4}>
                            <ToggleButtonGroup
                            color="primary"
                            value={panel}
                            exclusive
                            onChange={handleChange}
                            aria-label="Platform"
                            >
                                <ToggleButton value="region">Region</ToggleButton>
                                <ToggleButton value="industry">Industry</ToggleButton>
                                <ToggleButton value="company">Company</ToggleButton>
                                
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item xs={4}/>
                <Grid item xs={12}>
                    <DisplayPanel study={study} company={company} panel={panel}/>
                </Grid>
                <Grid item xs={12}>
                    <Box display={"flex"} justifyContent={"center"} width={1} marginBottom={2}>
                        {growth && 
                        <ModellingSlider newRevenue={newRevenue} modelledGrowth={growth} totalRevenue={total.total_previous_year.revenue} setNewRevenue={setNewRevenue} />
                        }
                    </Box>
                </Grid> 
            </Grid>
             
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSaveChanges}> Save </Button>
                
               
                <Dialog open= {confirmOpen} onClose={handleConfirmClose} maxWidth="sm">
                {loading && 
                    <Box sx={{ display: 'flex', width:1, justifyContent:'center' }}>
                        <CircularProgress />
                    </Box>
                }
                {(!loading) && 
                    <> 
                    <DialogContent>
                        <Typography variant={'h6'}> Confirm Submission </Typography>
                        <Typography variant={'body1'}> 
                            You have selected <b> ${newRevenue} </b>as the modelled total for {company.name}. Would you like to submit this? 
                        </Typography>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="note"
                            label="Note"
                            type="username"
                            fullWidth
                            variant="outlined"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            sx={{height:80}}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleSubmit}> Submit</Button>
                    </DialogActions>
                    </>
                }
                </Dialog>
            </DialogActions>
        </Dialog>
    )
}