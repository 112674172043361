import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';




const splitData = (data) => {
    
    const industries = [...new Set(data.map((d) => d.category))]
    const series = []
    const yearOne = data.filter((d) => d.year == 2023)
    const yearTwo = data.filter((d) => d.year == 2024)
    series.push({data: yearOne.map((d)=> d.revenue)})
    series.push({data: yearTwo.map((d)=> d.revenue)})
    return ({groups: industries, series: series})
}

export default function SegmentYoy({chartData}) {

    //const split = splitData(chartData)

  console.log(chartData)
  if (chartData) {

  
  return (
    <BarChart
    xAxis={[{ scaleType: 'band', 
                data: chartData.groups,
                tickLabelStyle: {
                    angle: -35,
                    textAnchor: 'end',
                    fontSize: 12,
                }}
            ]}
    series={chartData.series}
    margin={{bottom:150}}
    width={1300}
    height={600}
    />
  );
}
}