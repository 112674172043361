import * as React from 'react';
import { useEffect, useState } from 'react';
import {Box, CircularProgress} from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import useAPI from '../../hooks/useAPI';

const extractYears = (industry, data) => {
    return ([data.filter((d) => d.industry_name === industry &&  d.year == 2023)[0].total, data.filter((d) => d.industry_name === industry &&  d.year == 2024)[0].total] )
}
const splitData = (data) => {
    
    const industries = [...new Set(data.map((d) => d.category))]
    const series = []
    const yearOne = data.filter((d) => d.year == 2023)
    const yearTwo = data.filter((d) => d.year == 2024)
    series.push({data: yearOne.map((d)=> d.total)})
    series.push({data: yearTwo.map((d)=> d.total)})
    return ({groups: industries, series: series})
}

export default function IndustryBarChart({industry, company, study}) {
    const [chartData, setChartData] = useState({groups: [], series: []})
    const params = new URLSearchParams();
    if (study) {
        params.append('study_id', study.id)
    }
    if (company) {
        params.append('company_id', company.id)
    }
    if (industry) {
        params.append('industry_id', industry.id)
    }
    params.append('segment', 'industry')
    const {data, error, loading} = useAPI('concentrations', {params:params});
    useEffect(() => {
        if (data) {
            console.log(data.data)
            const split = splitData(data.data)
            console.log("split", split)
            setChartData(split)
        }
    }, [data])

    if (loading) {
        return <Box sx={{display: 'flex', justifyContent: 'center'}}><CircularProgress /></Box>
    }
  return (
    <BarChart
    xAxis={[{ scaleType: 'band', 
                data: chartData.groups,
                tickLabelStyle: {
                    angle: -35,
                    textAnchor: 'end',
                    fontSize: 12
                }}
            ]}
    series={chartData.series}
    sx={{marginBottom:15}}
    width={1300}
    height={600}
    />
  );
}