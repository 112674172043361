import React, {useState,useEffect} from 'react'
import { useParams } from 'react-router-dom';
import {useSnackbar} from '../../context/SnackbarContext';
import axiosInstance from '../../axiosConfig';

import {Box,
        Tabs,
        Tab,
        Grid,
        CircularProgress,
        Typography
         } from '@mui/material'
import DisplayDifferentiators from '../differentiator/DisplayDifferentiators';
import IndustryBarChart from '../charts/IndustryBarChart';



const TabPanel = ({value, industry}) => {
    switch(value) {
      case 'one':
        return <DisplayDifferentiators industry={industry}/>
      case 'two':
        return <IndustryBarChart industry={industry}/>
      case 'three':
        return <div>Differentiators</div>
      case 'four':
        return <div>Differentiators</div>
      case 'five':
        return <div>Differentiators</div>
      case 'six':
        return <div>Products</div>
      default:
        return <div>Overview</div>
    }
  }


export default function IndustryPage() {
    const {industryName} = useParams();
    const [loading, setLoading] = useState(false);
    const [industry, setIndustry] = useState(null)
    const [value, setValue] = useState('one');
    const { openSnackbar } = useSnackbar(); 

    useEffect(() => {
        const fetchIndustry = async () => {
            try {
                setLoading(true)
                const response = await axiosInstance.get('/industries/search',{params: {"name":industryName}})
                console.log("fetched industry", response.data.data) 
                setIndustry(response.data.data)
                setLoading(false)
            } catch (error){
                console.log("error fetching industry from url.", error )
                openSnackbar(error.message, 'error')
                setLoading(false)
        }}
    
        if (!industry) {
            console.log(" industry is null. time to fetch industry")
           fetchIndustry()
        }
            
    },[])
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    
    if (!industry) {
      console.log("no industry found loading")
      return (
        <Grid container alignItems={'center'} justifyContent={'center'}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', width:1, justifyContent:'center' }}><CircularProgress/></Box>
          </Grid>
        </Grid>

      )
    }
    else {
      console.log("industry found rendering rest of components", industry) 
      return (
        <>
        <Grid container alignItems={'center'} justifyContent={'center'}>
          <Grid item xs={12}>
            
              <Typography variant='h4' color='primary' sx={{fontWeight:'bold'}}> {industry.name} </Typography>
    
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ width: '100%', justifyContent: 'center', alignItems:'center', display:'flex' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="wrapped label tabs example"
              >
                <Tab value="one" label="Differentiators"/>
                <Tab value="two" label="Overview"/>
                <Tab value="three" label="Companies" />
              </Tabs>
    
            </Box>
          </Grid>
          <Grid item xs={12}>
          {loading &&  <Box sx={{ display: 'flex', width:1, justifyContent:'center' }}><CircularProgress/></Box>}
          {(!loading) && industry && <TabPanel value={value} industry={industry}/>}
          </Grid>
        </Grid> 
           
        </> )
    }
  }
    
