import React from 'react';

import './App.css';

import ResponsiveAppBar from './components/ResponsiveAppBar';

import CssBaseline from '@mui/material/CssBaseline';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { StudyContextProvider } from './context/StudyContext';


import { ThemeProvider } from '@emotion/react';
import theme from './theme';

import StudyDashboard from './components/pages/StudyDashboard';
import CompanyPage from './components/pages/CompanyPage';
import StudyCompanyPage from './components/pages/StudyCompanyPage';
import StudyPage from './components/pages/StudyPage';
import CompanyDashboard from './components/pages/CompanyDashboard';
import IndustryDashboard from './components/pages/IndustryDashboard';
import IndustryPage from './components/pages/IndustryPage';


function App() {

  
  return (
    
    <div className="App">
      <ThemeProvider theme= {theme}> 
      <Router> 
      <ResponsiveAppBar></ResponsiveAppBar>
      <CssBaseline />
      
      <StudyContextProvider> 
      
        <Routes>
            <Route path="/" element={<StudyDashboard />} />
            <Route path="/Suppliers" element={<CompanyDashboard/>} />
            <Route path="/Industries" element={<IndustryDashboard/>} />
            <Route path="/supplier-detail/:companyName" element={<CompanyPage/>} />
            <Route path="/industry-detail/:industryName" element={<IndustryPage/>} />
            <Route path="/study-detail/:studyName" element={<StudyPage/>} />     
            <Route path="/study-detail/:studyName/:companyName" element={<StudyCompanyPage/>} />
        </Routes>
        
      
      </StudyContextProvider>
      </Router>
      </ThemeProvider>

    </div>
    
  );
}

export default App;
