import {useState, useEffect} from 'react'
import {Dialog, Grid, Box, Button, CircularProgress, DialogTitle, Typography, DialogContent} from '@mui/material'
import useAPI from '../../../hooks/useAPI'
import { DataGrid } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const getGradientColor = (percent, max, min) => {
    const b = (percent)/(max-min);
    const c = (b*120)
    return `hsl(${c}, 60%, 70%)`
}

const renderGradient = (params) => {
    const background = getGradientColor(params.value, .1, -.1)
    return <Box sx={{height:'100%', width:'100%', backgroundColor: background}}> {`${(params.value*100).toFixed(2)} %`}</Box>
}
const renderArrow = (params) => {
    if (params.value > 0) {
        return (<Box sx={{height:'100%', width:'100%'}}><ArrowDropUpIcon fontSize={'large'} color={'success'}/>{`${(params.value*100).toFixed(2)} %`}</Box>)
    }
    if (params.value<0) {
        return (<Box sx={{height:'100%', width:'100%'}}><ArrowDropDownIcon fontSize={'large'} color={'error'}/>{`${(params.value*100).toFixed(2)} %`}</Box>)
    }
}

const displayYoy = (params) => {
        console.log(params)
        const value = params.value; // percent change value
        const barWidth = Math.abs(value) * 400;
        
        const isPositive = value > 0;
        
        // Inline styles for the bar
        const positiveBarStyle = {
            width: `${barWidth}%`,
            height: '10px',
            backgroundColor: 'green',
            position: 'absolute',
            //top:'15px',
            left: '50%', // start from the center for positive bars
            transformOrigin: 'left center',
            transform: 'translateX(0) translateY(-50%)', // no translation needed
          };
    
          const negativeBarStyle = {
            width: `${barWidth}%`,
            height: '10px',
            backgroundColor: 'red',
            
            position: 'absolute',
            right: '50%', // start from the center for negative bars
            transformOrigin: 'right center',
            transform: 'translateX(0) translateY(-50%)', // no translation needed
          };
  
        return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Box sx={{ position: 'relative', margin:'2px', width: '100%', height: '30px', display: 'flex', alignItems: 'center' }}>
                    <Box
                        sx={{
                        position: 'absolute',
                        left: '50%',
                        width: '1px',
                        height: '100%',
                        backgroundColor: 'black',
                        transform: 'translateX(-50%)', // center the line
                        }}
                    />
                    {value < 0 && 
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
                        <Box sx={negativeBarStyle} />
                    </Box>
                    }
                    {value >= 0 && 
                    <>
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start' }}>
                        <Box sx={positiveBarStyle}/>
                    </Box>
                    </>
                    }
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Typography variant='body2'> {`${(value*100).toFixed(2)} %`} </Typography>
            </Grid>
            
        </Grid>


        );
      }

const columnGrouping = [
    {groupId:2023,
     children: [{field:'revenue23'}, {field:'concentration23'}, {field:'share23'}],
     headerAlign:'center',
     headerClassName: 'year-group'
    },
    {groupId:2024,
        children: [{field:'revenue24'}, {field:'concentration24'}, {field:'share24'}],
        headerAlign:'center',
        headerClassName: 'year-group'

       },
    {groupId: 'YoY',
        children: [{field:'revenueYoY'}, {field:'shareYoY'}],
        headerAlign:'center',
        headerClassName: 'year-group'
    }
]

const splitData = (data, group_by) => {
    const groups = [...new Set(data.map((d) => d[group_by]))]
    
    const yearOne = data.filter((d) => d.year == 2023)
    const yearTwo = data.filter((d) => d.year == 2024)

    const mapped = groups.map((groupItem) =>  {
        const first = yearOne.filter((item) => item[group_by]===groupItem)[0];
        const second = yearTwo.filter((item) => item[group_by]===groupItem)[0];
            return ({revenue23: first.revenue,
                        share23: first.market_share,
                        concentration23: first.concentration,
                        revenue24: second.revenue,
                        share24: second.market_share,
                        concentration24: second.concentration,
                        shareYoY: ((second.market_share-first.market_share)/first.market_share),
                        revenueYoY: ((second.revenue-first.revenue)/first.revenue),
                        id: groupItem,
                        name: groupItem,
                        other: null
                    }
                    )})
                        
    return ({groups: groups, mapped:mapped})
                }
export default function CategoryCompanies({category, study, open, onClose} ) {
    const {data, error, loading} = useAPI('concentrations', {params:{study_id:study.id, category:category}},"failed to fetch concentrations", [category])
    const [rows, setRows] = useState(null);
    const navigate = useNavigate();
    const columns = [
        {field:'name',
            headerName:'Category',
            width:250,
            renderCell: (params) => {
                return <Button onClick={() => {navigate('/study-detail/'+study.name + '/'+params.value); onClose()}}><Typography color={'primary'} fontWeight={'bold'}>{params.value} </Typography></Button>}

         },
        {field:'revenue23',
             type:'number', 
             headerName:'Revenue', 
             width:100,
             valueFormatter: (value) => {
                return `$ ${value.toFixed(2)}`}
            },
        {field:'concentration23', headerName:'Concentration', width:100,
            valueFormatter: (value) => {
                return `${(value*100).toFixed(2)} %`}
        },
        {field:'share23', headerName: 'Share', width:100,
            valueFormatter: (value) => {
                return `${(value*100).toFixed(2)} %`}
        },
        {field:'revenue24',
            headerName:'Revenue',
            width:100,
            valueFormatter: (value) => {
                return `$ ${value.toFixed(2)}`}},
        {field:'concentration24', headerName:'Concentration', width:100,
            valueFormatter: (value) => {
                return `${(value*100).toFixed(2)} %`}
        },
        {field:'share24', headerName: 'Share', width:100,
            valueFormatter: (value) => {
                return `${(value*100).toFixed(2)} %`}
        },
        
        {field:'shareYoY', headerName: 'Yoy share', width:150,
            renderCell: (params) => {
                return (renderArrow(params))
            }
            //valueFormatter: (value) => {
             //   return `${(value*100).toFixed(2)} %`}
        },
        {field:'revenueYoY', headerName: 'Yoy revenue', width:100,
            valueFormatter: (value) => {
                return `${(value*100).toFixed(2)} %`}
        }]
    useEffect(() => {
        if (data) {
            const split = splitData(data.data, 'company_name')
            console.log("mapped in category modal", split.mapped)
            setRows(split.mapped)
        }
    },[data, category])

    return (
        <Dialog open={open} onClose={onClose} maxWidth={'lg'} fullWidth>
            <DialogTitle> <Typography variant='h4' textAlign={'center'} color='primary'>{category}</Typography></DialogTitle>
            <DialogContent>
                {loading && <Box sx={{width:1, display:'flex', justifyContent:'center', alignItems:'center', marginBottom:5}}> <CircularProgress/> </Box>}
                {rows && 
                    <DataGrid rows={rows}
                     columns={columns}
                     columnGroupingModel={columnGrouping}
                        
                     sx={{ '& .MuiDataGrid-row:nth-of-type(odd)': {
                          backgroundColor: '#f5f5f5', // Light gray for odd rows
                        },
                        '& .MuiDataGrid-row:nth-of-type(even)': {
                          backgroundColor: '#ffffff', // White for even rows
                        },'& .year-group': {color:'#1f409c', fontSize:'14pt', fontWeight:'bold'}}}
                     />
                }
            </DialogContent>
        </Dialog>
    )
}