import React, {useState } from 'react'

import { 
    Dialog, 
    DialogContent, 
    DialogActions, 
    Button, 
    TextField, 

    DialogTitle, 
    FormControl, 
    Checkbox,
    CircularProgress,
    Box,
    Typography,

    FormControlLabel
} from '@mui/material'
import { useSnackbar } from '../../../context/SnackbarContext';
import numberWithCommas from '../../../utils/formatNumber';
import axiosInstance from '../../../axiosConfig';

export default function OverrideRevenue({open, onClose, company, total, forceReload}) {
    const [isSupplierInput, setIsSupplierInput] = useState(false);
    const [value, setValue] = useState()
    const [loading, setLoading] = useState(false);
    const { openSnackbar } = useSnackbar();


    const handleSubmit = async () => {
        console.log('submit registered')
        setLoading(true);
        const num = Number(value.replace(",", ""))
        console.log(num)
        try {
            const status = isSupplierInput ? 'supplier_input' : 'override'
            const requestBody = {
                'status': status,
                'revenue': num}
            const response = await axiosInstance.put('company-totals/'+total.id, requestBody)
            forceReload()
            openSnackbar('Successfully submitted revenue for '+company.name)
        } catch(error) {
            console.log(error)
            openSnackbar('Failed to submit revenue', 'error')
        }
        setLoading(false);

        onClose()

    }
    return (
        <Dialog maxWidth='sm'
            fullWidth
            open={open} 
            onClose={onClose}>
            
            {loading && <DialogContent><Box sx={{width:1, display:'flex', justifyContent:'center', alignItems:'center'}}> <CircularProgress/> </Box></DialogContent>}
            {(!loading) && <>
            <DialogTitle>You can input your own value for the revenue here.</DialogTitle>
            <DialogContent>
                <Typography> The current modelled value for {total.company_name} is <b> ${numberWithCommas(total.revenue)} mil.</b></Typography>
                <FormControl fullWidth sx={{marginTop:3}}>

                    <TextField
                        variant='outlined'
                        label="Value"
                        margin="normal"
                        value={value}
                        onChange={(e) => {setValue(e.target.value)}}
                        />
                    <FormControlLabel
                        control = { <Checkbox onChange={(event) => setIsSupplierInput(event.target.checked)}/>}
                        label = "Is this number coming directly from a supplier? "
                    > </FormControlLabel>
                   
                
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={handleSubmit}>Submit</Button>
            </DialogActions> </>}
        </Dialog>
    )
}