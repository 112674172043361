import { Chip, Tooltip } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import StarIcon from '@mui/icons-material/Star';
import UpdateIcon from '@mui/icons-material/Update';

export default function StatusChip({status, selected, handleClick}) {
    const variant = selected?'filled':'outlined'

    switch (status) {
        case 'modelled':
            return (
                <Tooltip title='Modelled Revenue comes from the model only. '>
                    <Chip 
                        status={status} 
                        variant={variant} 
                        onClick={handleClick?(event)=> {handleClick(status)}:null} 
                        label={'Modelled'} color='warning' 
                        icon={<CurrencyExchangeIcon 
                        color={'warning'}/>}/>
                </Tooltip>
            )
        case 'revised':
            return (
                <Tooltip title="Revised revenues are revenues you adjust the growth rate only. using the modelling tool.">
                    <Chip 
                        status={status} 
                        variant={variant} 
                        onClick={handleClick?(event)=> {handleClick(status)}:null} 
                        label={'Revised'} 
                        color='secondary' 
                        icon={<UpdateIcon color={'secondary'}/>}/>
                </Tooltip>
            )
        case 'override':
            return (
                <Tooltip title="Revenues are tagged as overriden when you manually input a dollar figure.">
                    <Chip 
                        status={status} 
                        variant={variant} 
                        onClick={handleClick?(event)=> {handleClick(status)}:null} 
                        label={'Override'}color='info' 
                        icon={<CheckCircleOutlineIcon 
                        color={'info'}/>}/>
                </Tooltip>
                )
        case 'supplier_input':
            return (
                <Tooltip title="These are numbers coming directly from a supplier">
                <Chip 
                    status={status}
                    variant={variant} 
                    onClick={handleClick?(event)=> {handleClick(status)}:null}
                    label={'Supplier Input'}
                    color='success' 
                    icon={<StarIcon color={'success'} />}/>
                </Tooltip>
            )
    }
}