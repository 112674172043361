
import React, { useEffect } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import { Button, ToggleButton,ToggleButtonGroup, Stack } from '@mui/material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';

import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import '../../editorstyles.css';

const DiffEditor = ({content, onContentChange}) =>  {
  const editor = useEditor({
    extensions: [
      StarterKit,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
    ],
    content: content,
    onUpdate: ({ editor }) => {
      onContentChange(editor.getHTML());
    }
  });
  
  const [formats, setFormats] = React.useState(() => []);
  const [alignment, setAlignment] = React.useState(() => 'left');

  useEffect(() => {
    if (editor && content !== editor.getHTML()) {
      editor.commands.setContent(content);
    }
  }, [content, editor]);

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
    if (newFormats.includes('bold')) {
      editor.chain().focus().toggleBold().run();
    }
    if (newFormats.includes('italic')) {
      editor.chain().focus().toggleItalic().run();
    }
    if (newFormats.includes('underline')) {
      editor.chain().focus().toggleUnderline().run();
    }
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
    if (newAlignment === 'left') {
      editor.chain().focus().setTextAlign('left').run();
    }
    if (newAlignment === 'center') {
      editor.chain().focus().setTextAlign('center').run();
    }
    if (newAlignment === 'right') {
      editor.chain().focus().setTextAlign('right').run();
    }
    if (newAlignment === 'justify') {
      editor.chain().focus().setTextAlign('justify').run();
    }
  };


  return (
    <div>
      
      {/* Toolbar */}
      <Stack sx={{marginTop:1, marginBottom:1}} direction={'row'} spacing={4} className="toolbar" alignItems={'center'} justifyContent={'center'}>
        <ToggleButtonGroup
            value={formats}
            onChange={handleFormat}
            aria-label="text formatting"
            >
          <ToggleButton value="bold" aria-label="bold">
            <FormatBoldIcon />
          </ToggleButton>
          <ToggleButton value="italic" aria-label="italic">
            <FormatItalicIcon />
          </ToggleButton>
          <ToggleButton value="underlined" aria-label="underlined">
            <FormatUnderlinedIcon />
          </ToggleButton>
         </ToggleButtonGroup>
         <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text align"
            >
          <ToggleButton value="left" aria-label="left">
            <FormatAlignLeftIcon />
          </ToggleButton>
          <ToggleButton value="center" aria-label="center">
            <FormatAlignCenterIcon />
          </ToggleButton>
          <ToggleButton value="right" aria-label="right">
            <FormatAlignRightIcon />
          </ToggleButton>
          <ToggleButton value="justify" aria-label="justify">
            <FormatAlignJustifyIcon/>
          </ToggleButton>s
         </ToggleButtonGroup>
        <Button 
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()} 
          className={editor.isActive('heading', { level: 1 }) ? 'active' : ''}
          variant={editor.isActive('heading', { level: 1 }) ? 'contained' : 'text'} // Add this line
        >
          H1
        </Button>
        <Button 
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()} 
          className={editor.isActive('heading', { level: 2 }) ? 'active' : ''}
          variant={editor.isActive('heading', { level: 2 }) ? 'contained' : 'text'} // Add this line
        >
          H2
        </Button>
        <Button 
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()} 
          className={editor.isActive('heading', { level: 3 }) ? 'active' : ''}
          variant={editor.isActive('heading', { level: 3 }) ? 'contained' : 'text'} // Add this line
        >
          H3
        </Button>
      </Stack>

      {/* Editor Content */}
      <EditorContent editor={editor} className="editor-textarea"/>
    </div>
  );
}


export default DiffEditor;
