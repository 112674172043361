import React, { useState } from 'react';
import {Popper, Paper, MenuItem, ClickAwayListener} from '@mui/material';

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import EditNoteIcon from '@mui/icons-material/EditNote';
import HistoryIcon from '@mui/icons-material/History';

import ConcentrationNoteEditor from './ConcentrationNoteEditor';
import ConcentrationStatusDialog from './ConcentrationStatus'; 
import ConcentrationVersions from './ConcentrationVersions';
import CategoryCompanies from './CategoryCompanies';

const ActionsMenu = ({ anchorEl, open, onClose, concentration, study }) => {
    const [openNote, setOpenNote] = useState(false);
    const [openVersions, setOpenVersions] = useState(false);
    const [openAll, setOpenAll] = useState(false);
    const [openStatus, setOpenStatus] = useState(false);
    const handleClose = () => {
        setOpenNote(false)
        setOpenVersions(false)
        setOpenAll(false)
        setOpenStatus(false)
      }
    return (
<>
  <Popper key={concentration.name} open={open} anchorEl={anchorEl} disablePortal placement={'left-start'}>
    <ClickAwayListener onClickAway={onClose}>
      <Paper>
        <MenuItem onClick={setOpenAll}> <QueryStatsIcon sx={{marginRight:2}}/> All Suppliers</MenuItem>
        <MenuItem onClick={setOpenNote}><EditNoteIcon sx={{marginRight:2}}/> Edit Note</MenuItem>
        <MenuItem onClick={setOpenVersions}> <HistoryIcon sx={{marginRight:2}}/> See Versions</MenuItem>
      </Paper>
    </ClickAwayListener>
  </Popper>
  {openVersions && 
    <ConcentrationVersions concentration={concentration} open={openVersions} onClose={handleClose}/>
    }
  {openNote && 
   <ConcentrationNoteEditor concentration={concentration} open={openNote} onClose={handleClose}/>
   }
  {openStatus && 
   <ConcentrationStatusDialog concentration={concentration} open={openNote} onClose={handleClose}/>
   }
  {openAll && 
  <CategoryCompanies key={concentration.name} study={study} category={concentration.name} open={openAll} onClose={handleClose}/>
  }
  </>
  )
};

export default ActionsMenu;
