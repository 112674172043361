import {useState, useEffect} from 'react'
import useAPI from '../../hooks/useAPI'
import {Autocomplete, TextField} from '@mui/material'


export default function CompanyOptions({setCompany, company, disabled, study}) {
    const params = new URLSearchParams();
    if (study) {
        params.append('study_id', study.id)
    }
    const {data, error, loading} = useAPI('/companies', {params:params}, 'Error fetching companies')
    const [companies, setCompanies] = useState([])

    useEffect(() => {
        if (data) {
            setCompanies(data.data)
        }
    }, [data])

    return (
        <Autocomplete
            options={companies}
            disabled={disabled}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
                setCompany(newValue);
            }}
            value={company}
            renderInput={(params) => <TextField {...params} label="Company" />}
            fullWidth/>
    )
}