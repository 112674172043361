import {useState, useEffect} from 'react'
import {Grid, Box, CircularProgress} from '@mui/material'
import useAPI from "../../../hooks/useAPI";
import TotalCard from './TotalCard';
import ConcentrationChart from '../../charts/ConcentrationChart';
import { Refresh } from '@mui/icons-material';

export default function Totals({company, study}) {
    const {data, error, loading} = useAPI('company-totals', {params: {study_id:study.id, company_id:company.id}},"failed to load totals", [company])
    const [total, setTotal] = useState(null)
    const [totalLastYear, setTotalLastYear] = useState(null)


    useEffect(()=> {
        if (data) {
            console.log("fetched totals", data.data)
            const tot = data.data[1]
            const py = data.data[0]
            tot['total_previous_year'] = py
            setTotal(tot)
            setTotalLastYear(py)
        }
    }, [data])

    return (
        <Grid container spacing={2} justifyContent={'center'} alignContent={'center'} sx={{marginTop:3}}>
            {loading && 
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', width:1, justifyContent:'center' }}> <CircularProgress/> </Box>
                </Grid>   
            }
            {totalLastYear && (!loading) && <>
                <TotalCard company={company} study={study} companyTotal={total}/>
                <TotalCard company={company} study={study} companyTotal={totalLastYear}/>
                </>
            }

            <Grid sx={{height:400}} item xs={6}>
                <ConcentrationChart study={study} company={company} segment={"World Region"}/>
            </Grid> 
            <Grid sx={{height:400}} item xs={6}>
                <ConcentrationChart study={study} company={company} segment={"Industry"}/>
            </Grid>
        </Grid>
    )
}