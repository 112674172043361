import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import useAPI from '../../../hooks/useAPI';




function makeRows(data) {
    return data.map((item, index) => {
        return {
            id: item.id,
            concentration: item.concentration,
            time_updated: item.time_updated,
            user: item.edited_by.full_name,
            note: item.note,
        }
    })
}

const ConcentrationVersions = ({ open, onClose, concentration}) => {
    const { data, loading, error } = useAPI('concentrations/'+ concentration.id + '/versions');
    const [versions, setVersions] = useState([]);
    

    useEffect(() => {
        if (data){ 
            setVersions(data.data);
        }

    },[open, data]);


    const columns = [
        { field: 'time_updated', headerName: 'Last Edited', width: 150 },
        { field: 'concentration', headerName: 'Concentration', width: 150 },
        { field: 'user', headerName: 'Edited By', width: 150 },
        { field: 'note', headerName: 'Note', width: 150 },
    ];

    return (
        <div>

            <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
                <DialogTitle>Concentration Versions</DialogTitle>
                <DialogContent>
                    {loading && <p>Loading...</p>}
                    {!loading &&
                    <div style={{ height: 400, width: '100%' }}>
                        <DataGrid rows={makeRows(versions)} columns={columns} pageSize={5} />
                    </div>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {onClose()}}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ConcentrationVersions;