
import {Grid, Box, Typography} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import ConcentrationChart from '../../../charts/ConcentrationChart'
import useAPI from '../../../../hooks/useAPI'
import Loading from '../../../loading/Loading'
import formatDecimal from '../../../../utils/formatDecimal'

export default function FinancialsPanel({company, study}) {
    const {data, loading, error} = useAPI('financials', {params:{resource:'region', quarter:'Q2', year:2024}})
    const year = 2024


    const columns = [
        {
            field:'name', 
            headerName:"Name", 
            width:250
        },
        {
            field:'year_over_year_change',
            headerName: "YoY Change",
            width:100,
            valueFormatter: (value)=> formatDecimal(value)
        }
    ]
    return (
            <Grid container justifyContent={'center'} alignItems={'center'} spacing={5}>

            
            
                <>
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                    <Box  marginBottom={5} width={1}>
                        <Typography textAlign={'center'} variant='h6' color='primary'> Financials Growth</Typography>
                        {loading && 
                        <Loading/>
                        }
                        {data && 
                        <DataGrid sx={{height:250}} rows={data.data} columns={columns} hideFooter/>
                        }
                    </Box>
                </Grid> 
                <Grid item xs={6}>
                    <Box marginBottom={5} width={1}>
                    <ConcentrationChart study={study} company={company} segment={'World Region'}/>
                    </Box>
                </Grid>
                <Grid item xs={1}></Grid>
                </>
            
            </Grid>
       
    )
}