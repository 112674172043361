import {useState, useEffect} from 'react'
import { Grid, Typography } from "@mui/material";
import StatusChip from '../../../chips/StatusChip';


export default function StatusSelect({setStatuses}) {
    const [selected, setSelected] = useState({'modelled':false, 'supplier_input':false, 'override':false, 'revised':false})
    const statusOptions = ['supplier_input', 'revised', 'override','modelled' ]
    const handleChange = (status) => {
        console.log(status)
        const newVal = !selected[status]
        setSelected({...selected, [status]:newVal})
      };

    useEffect(() => {
        setStatuses(Object.keys(selected).filter(key => selected[key]===true))
    }, [selected])
    
    return (
    <Grid container justifyContent={'center'} alignItems={'center'} spacing={1}>
        <Grid item xs={12}>
          <Typography variant='body1' color='primary'> Filter by Status</Typography>
        </Grid>
        {statusOptions.map( status => 
            <Grid item xs={6}>
                <StatusChip handleClick={handleChange} selected={selected[status]} status={status} />
            </Grid>
            )}
      </Grid>
    )
}