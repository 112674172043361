import React, {useState, useEffect} from 'react'
import { Container, Typography, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import ActionsButton from '../../../buttons/ActionsButton'
import useAPI from '../../../../hooks/useAPI'
import Loading from '../../../loading/Loading'
import StatusChip from '../../../chips/StatusChip'
import formatCurrency from '../../../../utils/formatCurrency'
import formatDecimal from '../../../../utils/formatDecimal'
import ConcentrationActions from './concentration_actions/ConcentrationActions'

const makeRows = (data, year) => {

    const rows = data.map((item) => {
        const recordPY = item.previous_year_record
    return  (
        {
            ...item,
            total_status: item.total_record.status,
            revenue_py: recordPY.revenue,
            time_updated_py: recordPY.time_updated,
            change_year_over_year: (item.revenue-recordPY.revenue)/recordPY.revenue,
        }
    )
})
    return rows.sort((a,b) => b.revenue-a.revenue)
}


export default function CategoryDetail({study, segment, category, year, forceReload}) {
    const {data, error, loading} = useAPI('studies/' + study.id + '/segments/' + segment.name +'/concentrations/'+ category.name, {params:{year:year}})
    const [rows, setRows] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event, companyId) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
        setSelectedCompanyId(companyId)
        console.log("selecting id", companyId)
      };
      const handleMenuClose = () => {
          setAnchorEl(null);
          setMenuOpen(false);
        };

    useEffect(() => {
        if (data) {
            setRows(makeRows(data.data))
        }
    }, [data])

    const columns = [
        {
            field: 'company_name',
            headerName: "Company", 
            flex:4,
            renderCell: (params) => <Typography color='primary'>{params.value}</Typography>
        },
        {
            field: 'revenue_py', 
            type:'number', 
            headerName: `${year-1}`, 
            flex:1, 
            renderCell: (params) => 
            <Tooltip title={
            <React.Fragment>
                <Typography variant={'body2'}>
                    Last Edited: {params.row.time_updated_py} 
                </Typography>
                <Typography variant={'body2'}> 
                    Last Edited By: Test User
                </Typography> 
            </React.Fragment>}><span>{formatCurrency(params.value)}</span> 
        </Tooltip> },
        {field: 'revenue', type:'number', headerName: `${year}`, flex:1, valueFormatter: (value) => formatCurrency(value)},
        {field: 'concentration', type:'number',headerName: 'Concentration', flex:1, valueFormatter: (value) => formatDecimal(value)},
        {field: 'market_share', type:'number', headerName: 'Market Share', flex:1, valueFormatter: (value) => formatDecimal(value)},
        {field: 'total_status', headerName: 'Total Status', flex:1.5, renderCell: (params) => <StatusChip status={params.value}/>},
        {field: 'actions', type:'actions',  renderCell: (params) => (
            <React.Fragment>
                <ActionsButton buttonType={'slim'}  handleClick={(event) => handleMenuOpen(event, params.row.company_id)}></ActionsButton>
                <ConcentrationActions 
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                    company={{id:params.row.company_id, name:params.row.company_name}} 
                    study={study} 
                    segment={segment} 
                    category={category.name}
                    forceReload={forceReload}
                    open= {menuOpen && selectedCompanyId ===params.row.company_id}
                    />
            </React.Fragment>
            )}
    ]
    return (
        <Container>
            {loading && 
                <Loading/>
            }
            {rows && 
            <DataGrid 
                rows={rows} 
                columns={columns}
                initialState={{
                    pagination: {
                      paginationModel: { pageSize: 5 },
                    },
                  }}
                pageSizeOptions={[5, 10, 25]} 
                sx={{
                    '& .MuiDataGrid-row:nth-of-type(odd)': {
                      backgroundColor: '#f5f5f5', // Light gray for odd rows
                    },
                    '& .MuiDataGrid-row:nth-of-type(even)': {
                      backgroundColor: '#ffffff', // White for even rows
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        fontWeight: 'bold',
                      },
                }}
                />
            }
        </Container>
    )
}