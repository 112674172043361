import {Grid, Typography, Box} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import ConcentrationChart from '../../../charts/ConcentrationChart'
import useAPI from '../../../../hooks/useAPI'
import formatDecimal from '../../../../utils/formatDecimal'
import Loading from '../../../loading/Loading'

export default function IndustryPanel({company, study}) {
    const {data, loading, error} = useAPI('financials', {params:{resource:'industry', quarter:'Q2', year:2024}})
    const columns = [
        {
            field:'name', 
            headerName:"Name", 
            width:200
        },
        {
            field:'year_over_year_change',
            headerName: "YoY Change",
            width:150,
            valueFormatter: (value)=> formatDecimal(value)
        }
    ]
    return (

        <Grid container justifyContent={'center'} alignItems={'center'} spacing={5}>
        
            <>
            <Grid item xs={1}/>
            <Grid item xs={4}>
                <Typography textAlign={'center'} variant='h6' color='primary'> Financials Growth</Typography>
                {loading && 
                    <Loading/>
                }
                {data && 
                    <DataGrid 
                        rowHeight={35} 
                        rows={data.data} 
                        columns={columns} 
                        hideFooter  
                        sx={{height:400, '& .MuiDataGrid-row:nth-of-type(odd)': {
                            backgroundColor: '#f5f5f5', // Light gray for odd rows
                            },
                            '& .MuiDataGrid-row:nth-of-type(even)': {
                            backgroundColor: '#ffffff', // White for even rows
                            }}}/>
                    }
            </Grid> 
            <Grid item xs={6}>
                <Box marginBottom={5} width={1}>
                <ConcentrationChart study={study} company={company} segment={'Industry'}/>
                </Box>
            </Grid>
            <Grid item xs={1}/>
            </>
</Grid>
    )
}