import {useState, useEffect} from 'react'
import {Box, Grid, Typography, Paper, Divider} from '@mui/material'
import SegmentYoY from '../../charts/SegmentYoY'
import SegmentOptions from '../../autocomplete/SegmentOptions'
import useAPI from '../../../hooks/useAPI';
import numberWithCommas from '../../../utils/formatNumber'


const splitData = (data) => {
    
    const industries = [...new Set(data.map((d) => d.category))]
    const series = []
    const yearOne = data.filter((d) => d.year == 2023)
    const yearTwo = data.filter((d) => d.year == 2024)
    series.push({data: yearOne.map((d)=> d.revenue)})
    series.push({data: yearTwo.map((d)=> d.revenue)})
    const mapped = industries.map((ind) => ( 
                                    {
                                        yearOne: yearOne.filter((item) => item.category===ind)[0].revenue,
                                        yearTwo: yearTwo.filter((item) => item.category===ind)[0].revenue,
                                        name: ind
                                    }
                                            )
                                    )
    return ({groups: industries, series: series, mapped:mapped})
}


export default function SegmentYearOnYear({company, study}) {
    const [chartData, setChartData] = useState(null);
    const [segment, setSegment] = useState({name:'Industry', label:'Industry'});
    const [params, setParams] =  useState({'study_id':study.id, 'company_id': company.id, 'segment':'Industry'})
    const [reload, setReload] = useState(false)
    const {data, error, loading} = useAPI('concentrations', {params:params}, 'Error loading data', [reload]);

    useEffect(() => {
        params.segment = segment.name
        console.log("set params", params)
        setReload(!reload)
    },[segment])

    useEffect(() => {
        if (data) {
            setChartData(splitData(data.data))
        }
    },[data, segment,params])

    return (
        <Grid container spacing={2} alignContent={'center'} justifyContent={'center'}>
            <Grid item xs={12}>
                <SegmentOptions study={study} segment={segment} setSegment={setSegment}/>
            </Grid>
            <Grid reload={reload} item xs={12} sx={{marginBottom:5}}>
                {chartData && 
                <SegmentYoY chartData={chartData} />
                }       
            </Grid>
            {chartData && 
            <Grid item xs={10}>
                <Paper elevation={4}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Typography variant='h6' color='primary'>
                            Category
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                    <Typography variant='h6' color='primary'>
                            2023
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant='h6' color='primary'>
                                2024
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant='h6' color='primary'>
                                %
                        </Typography>
                    </Grid>
                     {chartData.mapped.map(cat => 
                        <> 
                        <Grid item xs={4}>
                            {cat.name}
                        </Grid>
                        <Grid item xs={3}>
                           $ {numberWithCommas(cat.yearOne.toFixed(2))}
                        </Grid>
                        <Grid item xs={3}>
                           $ {numberWithCommas(cat.yearTwo.toFixed(2))}
                        </Grid>
                        <Grid item xs={2}>
                            <Typography color='primary' fontWeight='bold'> 
                                {(((cat.yearTwo-cat.yearOne)/cat.yearOne)*100).toFixed(2)} %
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        </>
                        )}
                    </Grid>
                </Paper>
            </Grid>}
        </Grid>
    )
}