import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, CircularProgress, DialogActions, TextField, Button } from '@mui/material';
import axiosInstance from '../../../axiosConfig';


const ConcentrationNoteEditor = ({ concentration, open, onClose }) => {
    const [loading, setLoading] = useState(false)
    const [note, setNote] = useState(concentration?.note);
    const handleNoteChange = (event) => {
        setNote(event.target.value);
    };

    const handleSave = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.put('concentrations/' + concentration.id, {note: note})
            console.log("submitted concentration update",response)
        }
        catch (error) {
            console.error('Error updating concentration status:', error);
        }
        //saveNote(concentration.category, note);
        setLoading(false)
        onClose();
    };

    const handleCancel = () => {
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>Edit Note</DialogTitle>
            <DialogContent
                sx={{marginTop:2}}>
                <TextField
                    autoFocus
                    multiline
                    rows={4}
                    fullWidth
                    value={note}
                    onChange={handleNoteChange}
                    label="Note"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleSave} variant="contained" color="primary">
                    {loading && 
                    <CircularProgress size={20} color='background' />}
                    {(!loading) &&
                        <>Save</>
                    }
                    
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConcentrationNoteEditor;