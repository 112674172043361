import React, { createContext, useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import axiosInstance from '../axiosConfig';
import { useSnackbar } from './SnackbarContext';
export const StudyContext = createContext();


export const StudyContextProvider = ({ children }) => {
  const [study, setStudy] = useState({"name":null, "id":null, "analyst":null, "links":null});
  const { openSnackbar } = useSnackbar();
  const {studyName} = useParams(null);

  const reload = 
  useEffect(() => {
    const fetchStudy = async () => {
        try {
            console.log("fetching study")
            const response = await axiosInstance.get('/studies/search',{params: {"name":studyName}})
            console.log("fetched study", response.data.data) 
            setStudy(response.data.data)
        } catch (error){
            console.log("error fetching study from url.", error )
            openSnackbar(error, 'error')
    }}
    console.log("studyName", studyName)
    console.log("study", study)

    if (study === null && studyName !== null) {
        console.log("time to fetch study")
       fetchStudy()
    }
        
},[study, studyName]
)

  return (
    <StudyContext.Provider value={{ study, setStudy }}>
      {children}
    </StudyContext.Provider>
  );
};

export const useStudy = () => {
    return useContext(StudyContext);
  };