import React from 'react'

import {Pie} from 'react-chartjs-2'

const options = {
    maintainAspectRatio: false,
    tooltips: {
    backgroundColor: "rgb(255,255,255)",
    bodyFontColor: "#858796",
    borderColor: '#dddfeb',
    borderWidth: 1,
    xPadding: 15,
    yPadding: 15,
    displayColors: false,
    caretPadding: 10,
    },
    legend: {
    display: true,
    position: 'bottom'
    },
    cutoutPercentage: 80,
    }




function PieChart({chartData}) {
    return (
    <div className="chart-container" style={{height:'300px'}}>
        <Pie
            data={chartData}
            options = {options}
        ></Pie>
        </div>
    )
}

export default PieChart