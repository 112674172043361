
import { 
    Typography,
    Grid,
    Box,
    CircularProgress
   } from '@mui/material';
  
  import React, { useState, useEffect } from 'react';
  
  import {useNavigate} from 'react-router-dom'
  import useAPI from '../../hooks/useAPI'
  import {useStudy} from '../../context/StudyContext'
  import StudyCard from '../studydashboard/StudyCard';
  

  export default function StudyDashboard() {
  
    const {setStudy}  = useStudy()
    const  navigate  = useNavigate();
    const {data, error, loading} = useAPI('/@me/studies', {})
      const handleClick = (study) => {
          setStudy(study)
          navigate('/study-detail/'+ study.name)
      };

      const[studies, setStudies] = useState([])
  
      useEffect(() => {
        if (data) {
          setStudies(data.data)
          console.log(data.data)
        }
      }
    )
  
      return (
          <Grid container width={1} spacing={3} alignItems='flex' justifyContent='center'> 
          
            {studies.length === 0 && (!loading) && <Box sx={{width:1, display:'flex', justifyContent:'center', alignItems:'center'}}> <Typography variant={'body1'}> No studies found! Add yourself to an existing study or migrate a legacy study from the database. </Typography> </Box>}
            {error && (!loading) && <Box sx={{width:1, display:'flex', justifyContent:'center', alignItems:'center'}}> <Typography> Error loading studies </Typography> </Box>}
            {loading && <Box sx={{width:1, marginTop:10, display:'flex', justifyContent:'center', alignItems:'center'}}> <CircularProgress/> </Box>}
            {(!loading) && 
    
              studies.map((study) => ( 
                
                    <StudyCard study={study} handleClick={handleClick} />
              ))
              }
          </Grid>
      )}