import React, { createContext, useContext, useState, useEffect, useRef } from 'react';

import axiosInstance from '../axiosConfig';
import { useSnackbar } from './SnackbarContext';
import LoginModalv2 from '../components/auth/LoginModal';

const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState();
  const timeoutRef = useRef();
  const [openLogin, setOpenLogin] = useState(false);
  const {openSnackbar} = useSnackbar();

  const handleClose = () => {
    setOpenLogin(false);
  }
  const openLoginModal = () => {
    setOpenLogin(true);
  }
  const handleTimeout = () => {
    setUser(null);
    setOpenLogin(true);
    openSnackbar("Session expired. Please log in again.", 'info');
  };

  const setSessionTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(handleTimeout, 30 * 60 * 1000); // 30 minutes
  };
  const login = async (username, password) => {
    try {
      const response = await axiosInstance.post('/login', { username, password });
      setUser(response.data.user);
      setSessionTimeout();
      openSnackbar("You've logged in.", 'success');
    } catch (err) {
      

      openSnackbar("Login failed.", 'error')
      setOpenLogin(true)
    }
  };
  const checkAuth = async () => {

    try {
      const response = await axiosInstance.get('/@me');
      console.log("checking auth. response:", response)
      setUser(response.data.user);

    } catch (err) {
      setUser(null);
      setOpenLogin(true)
    }
  };

  useEffect(() => {
    if (!user)  {
      checkAuth()
    }
  }, [user])

  return (
    <UserContext.Provider value={{user, setUser, openLoginModal}}>
      {children}
      <LoginModalv2 open={openLogin} handleClose={handleClose} login={login}/>
    </UserContext.Provider>
  );
};

export const useUser = () => { return useContext(UserContext); };