import React, { useState } from 'react';
import {Popper, Paper, MenuItem, ClickAwayListener } from '@mui/material';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import MergeIcon from '@mui/icons-material/Merge';
import HistoryIcon from '@mui/icons-material/History';
import RateReviewIcon from '@mui/icons-material/RateReview';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import MergeModal from './MergeModal';
import DeleteCompany from './DeleteCompany';
import FinancialsModal from './FinancialsModal';
import AddBriefing from './AddBriefing'
import DifferentiatorModal from '../../differentiator/DifferentiatorModal'

const CompanyActions = ({ anchorEl, open, onClose, company, study }) => {
    const [openMerge, setOpenMerge] = useState(false)
    const [openFinancials,setOpenFinancials ] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openBriefing, setOpenBriefing] = useState(false)
    const [openDifferentiator, setOpenDifferentiator] = useState(false);

    const handleClose = () => {
      setOpenMerge(false)
      setOpenFinancials(false)
      setOpenDelete(false)
      setOpenBriefing(false)
      setOpenDifferentiator(false)
      }
    
    return (
<>
  <Popper key={company.name} open={open} anchorEl={anchorEl} disablePortal placement={'right-start'}>
    <ClickAwayListener onClickAway={onClose}>
      <Paper sx={{backgroundColor:'white'}}>
        <MenuItem onClick={() => {setOpenMerge(true)}}> <MergeIcon sx={{marginRight:2}}/> Merge Suppliers</MenuItem>
        <MenuItem onClick={() => {setOpenFinancials(true)}}><QueryStatsIcon sx={{marginRight:2}}/> Financials</MenuItem>
        <MenuItem onClick={null}> <HistoryIcon sx={{marginRight:2}}/> See History</MenuItem>
        <MenuItem onClick={() => {setOpenDifferentiator(true)}}> <RateReviewIcon sx={{marginRight:2}}/> Add Differentiator</MenuItem>
        <MenuItem onClick={() => {setOpenBriefing(true)}}><InsertInvitationIcon sx={{marginRight:2}}/> Schedule Briefing</MenuItem>
        <MenuItem onClick={() => {setOpenDelete(true)}}> <RemoveCircleOutlineIcon sx={{marginRight:2}}/> Remove Supplier</MenuItem>
      </Paper>
    </ClickAwayListener>
  </Popper>
  {openMerge && 
    <MergeModal study={study} company={company} open={openMerge} onClose={handleClose}/>
  }
  {openFinancials &&
    <FinancialsModal open={openFinancials} company={company} onClose={handleClose}/>
  }
  {openDelete && 
    <DeleteCompany company={company} study={study} open={openDelete} onClose={handleClose}/>
  }
  {openBriefing && 
    <AddBriefing company={company} study={study} open={openBriefing} onClose={handleClose}/>
  }
  {openDifferentiator && 
    <DifferentiatorModal company={company} study={study} open={openDifferentiator} onClose={handleClose}/>
  }
  </>
  )
};

export default CompanyActions;
