import * as React from 'react';
import {useState} from 'react'
import { Paper,Popper, MenuItem, ClickAwayListener } from '@mui/material';
import OverrideRevenue from './OverrideRevenue'
import ModellingModal from './ModellingModal'
import TotalNoteEditor from './TotalNoteEditor'
import TotalVersions from './TotalVersions'
import EditIcon from '@mui/icons-material/Edit';
import EditNoteIcon from '@mui/icons-material/EditNote';
import HistoryIcon from '@mui/icons-material/History';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

export default function TotalActions({anchorEl, open, onClose, total, company, study, forceReload}) {

  const [openOverride, setOpenOverride] = useState(false);
  const [openModelling, setOpenModelling] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [openVersions, setOpenVersions]= useState(false);
 
  const handleCloseOverride = () => {
    setOpenOverride(false)
  }
  const handleCloseModelling = () => {
    setOpenModelling(false)
  }
  const handleCloseVersions = () => {
    setOpenVersions(false)
  }
  const handleCloseNote = () => {
    setOpenNote(false)
  }
 

  return (
    <>
      <Popper open={open} anchorEl={anchorEl} >
      <ClickAwayListener onClickAway={onClose}>
        <Paper> 
        {total.total_previous_year && 
          <MenuItem onClick={setOpenModelling}><AutoGraphIcon sx={{marginRight:2}}/> Modelling</MenuItem>
        }    
        <MenuItem onClick={setOpenOverride}>
          <EditIcon sx={{marginRight:2}}/>
            Override Revenue
        </MenuItem> 
        <MenuItem onClick={setOpenNote}>
          <EditNoteIcon sx={{marginRight:2}}/>
            Note
        </MenuItem>
        <MenuItem onClick={setOpenVersions}>
          <HistoryIcon sx={{marginRight:2}}/>
          See Versions
        </MenuItem>
        <MenuItem onClick={setOpenVersions}><RemoveCircleIcon sx={{marginRight:2}}/> Remove Supplier</MenuItem>
        </Paper>
        </ClickAwayListener>


      </Popper>

      {total.total_previous_year && openModelling && 
        <ModellingModal open={openModelling} onClose={handleCloseModelling} company={company} study={study} total={total}/>
      }
      {openNote && 
      <TotalNoteEditor open={openNote} onClose={handleCloseNote} total={total} company={company}/>
      }
      {openVersions && 
      <TotalVersions open={openVersions} onClose={handleCloseVersions} totalId={total.id}/>
      }
      {openOverride &&    
      <OverrideRevenue forceReload={forceReload} company={company} open={openOverride} onClose={handleCloseOverride} total={total}/>
      }
    </>
  )
}

