import {useState} from 'react'
import {Grid, Container, Paper, Button, Stack} from '@mui/material'
import SegmentOptions from '../../../autocomplete/SegmentOptions'
import MarketAnalysisAccordion from './MarketAnalysisAccordion'
import SwapVertIcon from '@mui/icons-material/SwapVert';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import BarChartIcon from '@mui/icons-material/BarChart';
import AddIcon from '@mui/icons-material/Add';

export default function MarketAnalysis({study}) {
    const [segment, setSegment] = useState({name:'Industry'})


    return (
        <Container sx={{marginTop:5}}>
            <Paper elevation={3} sx={{marginBottom:4}}>
                <Grid container spacing={2}>
                    <Grid item xs={4} sx={{marginBottom:1}}>
                        <SegmentOptions study={study} segment={segment} setSegment={setSegment}/>
                    </Grid>
                    <Grid item xs={2}/>
                    <Grid item xs={6}>
                        <Stack sx={{marginTop:5}} direction={'horizontal'}>
                            <Button sx={{marginRight:1}} color='primary' variant='contained' startIcon={<SwapVertIcon/>}>Sort </Button>
                            <Button sx={{marginRight:1}} color='primary' variant='contained' startIcon={<FilterAltIcon/>}>Filter </Button>
                            <Button sx={{marginRight:1}} color='primary' variant='contained' startIcon={<BarChartIcon/>}>View Chart</Button>
                            <Button sx={{marginRight:1}} color='primary' variant='contained' startIcon={<AddIcon/>}> Add Category </Button>
                        </Stack>
                    </Grid>

                </Grid>
            </Paper>
            
            
            <MarketAnalysisAccordion study={study} segment={segment}/>
            
        </Container>
    )
}