import React from 'react';
import { Dialog,
        DialogTitle, 
        DialogContent, 
        DialogActions, 
        RadioGroup, 
        FormControlLabel, 
        Radio, 
        Button,
        CircularProgress,
        Box } from '@mui/material';

import { useSnackbar } from '../../../context/SnackbarContext';
import axiosInstance from '../../../axiosConfig';

const ConcentrationStatusDialog = ({ open, onClose, onSaveChanges, concentration }) => {
    const {openSnackbar} = useSnackbar()
    const defaultSelection = concentration.status
    const [loading, setLoading] = React.useState(false);
    const [selectedOption, setSelectedOption] = React.useState(defaultSelection);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    
    const handleSaveChanges = async () => {
        
        try {
            setLoading(true)
            const response = await axiosInstance.put('concentrations/' + concentration.id, {status: selectedOption})
            console.log("submitted concentration",response)
            openSnackbar("Updated Status", 'success')
            onSaveChanges(concentration, selectedOption);
            setLoading(false)
            onClose();
        }
        catch (error) {
            console.error('Error updating concentration status:', error);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Concentration Status</DialogTitle>
            {loading && <Box sx={{width:1, display:'flex', justifyContent:'center', alignItems:'center', marginBottom:5}}> <CircularProgress/> </Box>}
            {(!loading) && <>
            <DialogContent>
                <RadioGroup value={selectedOption} onChange={handleOptionChange}>
                    <FormControlLabel value="override" control={<Radio />} label="Override" />
                    <FormControlLabel value="supplierInput" control={<Radio />} label="Supplier Input" />
                    <FormControlLabel value="modelled" control={<Radio />} label="Modelled" />
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={() => handleSaveChanges()} variant="contained" color="primary">Save Changes</Button>
            </DialogActions>
            </>
    }
        </Dialog>
    );
};

export default ConcentrationStatusDialog;