import { Button, TextField,Grid, Dialog, IconButton, DialogTitle, DialogActions, DialogContent, Typography } from "@mui/material"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloseIcon from '@mui/icons-material/Close'

export default function FinancialsModal({open, onClose, company}) {

    return (
        
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography width={.8} variant={'h6'} fontWeight='bold' color='primary' textAlign={'center'}> Schedule a briefing with {company.name}</Typography>
            </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <CloseIcon />
                </IconButton>
            <DialogContent>
                <Grid container spacing={3} justifyContent={'center'} alignItems={'center'}>
                    <Grid item xs={8}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker label={'Pick a Date for the Briefing.'}></DatePicker>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField label={'Add a note...'}></TextField>
                    </Grid>
                </Grid>
                
                
            </DialogContent>
            <DialogActions>
                <Button>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}