import * as React from 'react';
import { Stepper, Paper, Chip, Step, StepLabel, StepButton, StepContent, Button, Typography, Stack, IconButton} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/Check';



export default function Instructions({study}) {
        const completed = {
                0:true,
                1:false,
                2:false,
                3:false,
                4: false,
        }

        const steps = [
        {
            label: 'Run Model',
            description: `The first step is to populate this year's study data with modelled data.`,
            actions: study.status==='not_started'?<Button color='success'> Run Model</Button>: null
        },
        {
            label: 'Review Modelling',
            description: <>
                <Typography fontWeight={'bold'}> The Company Analysis and Segment Analysis tabs above will show you how this years study data looks.</Typography>
                <Typography textAlign='center' variant='h6' color='primary'>Company Analysis: </Typography>
                <Typography textAlign='left'> The Company Analysis pane contains top-down analysis. You can adjust each company's modelled revenue, overwrite it, or input 
                data directly from the supplier. </Typography>
                <Typography textAlign='center' variant='h6' color='primary'>Segment Analysis: </Typography>
                <Typography textAlign='left'>On the Segment Analysis pane you will find an overview of the market by segment. 
                    You can view changes in market share, in each segment and adjust each company's concentration of business in that segment. </Typography>
                </>
        },
        {
            label: 'Generate Surveys and Meet with Suppliers',
            description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
        },
        {
            label: 'Review the Forecast',
            description: `Try out different ad text to see what brings in the most customers,
                    and learn how to enhance your ads using features like ad extensions.
                    If you run into any problems with your ads, find out how to tell if
                    they're running and how to resolve approval issues.`,
        },
        ];


        const [activeStep, setActiveStep] = React.useState(study.status==='modelling'? 1:0);

        const handleStep = (step) => {
          setActiveStep(step)
        }
        const handleNext = () => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        };

        const handleBack = () => {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        };

        const handleReset = () => {
            setActiveStep(0);
        };

  return (
    <Paper sx={{backgroundColor:'#d3dceb', padding:2}} elevation={3}>
      <Stepper activeStep={activeStep} orientation="vertical" nonLinear>
        {steps.map((step, index) => (
          <Step key={step.label} completed= {completed[index]}>
            <StepButton color={'inherit'} onClick={() => handleStep(index)}>
              <StepLabel

              >
              <Typography variant='h6' color={index===activeStep?'primary':'#737980'} fontWeight='bold' > {step.label} </Typography> 
              </StepLabel>
            </StepButton>
            
            <StepContent>
              <Typography>{step.description}</Typography>
              <>{step.actions}</>
              <>{completed[index]? <Chip color='success' variant='filled' icon={<CheckIcon/>} label='You have completed this step.'> </Chip>: null}</>
              <Stack maxWidth={.15}>
                <IconButton
                  disabled={index === 0}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1, height:5, width:5}}
                >
                   <KeyboardArrowUpIcon/>
                </IconButton>
                <IconButton
                  disabled={index===(steps.length-1)}
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1, height:5, width:5 }}
                >
                   <KeyboardArrowDownIcon/>
                </IconButton>
              </Stack>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Paper>
  );
}
