import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DisplayCategory from './DisplayCategory';
import CategoryDetail from './CategoryDetail';

export default function CategoryPanel({study, segment, forceReload, category, year, expanded, handleChange}) {
    

    return (
        
        <Accordion 
        slotProps={{ transition: { unmountOnExit: true } }}
        expanded={expanded===category} onChange={handleChange(category)}
        sx ={{ marginRight: 1}}
        elevation={3}
        >
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            key={category.name}
            id= {`${category.name}-header`}
            sx= {{height:65, width:1}}
            >
                <DisplayCategory segment={segment} category={category} year={year} />

            </AccordionSummary>
            <AccordionDetails>
                {(expanded===category) &&
                <CategoryDetail study={study} segment={segment} category={category} year={year} forceReload={forceReload}/>
                }
            </AccordionDetails>
            </Accordion> 


    )}