import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import useAPI from '../../../hooks/useAPI';
import axiosInstance from '../../../axiosConfig';
import StatusChip from '../../chips/StatusChip';

function makeRows(data) {
    return data.map((item) => {
        return {
            id: item.id,
            total: item.revenue,
            time_updated: new Date(item.time_updated),
            user: item.edited_by.full_name,
            note: item.note,
            status: item.status
        }
    }).sort((a,b) => b.time_updated - a.time_updated);
}

const TotalVersions = ({open, onClose, totalId}) => {
    const { data, loading, error } = useAPI('company-totals/'+ totalId + '/versions');
    const [versions, setVersions] = useState([]);   
    const [rows, setRows] = useState([]);
    useEffect(() => {
        if (open && data){ 
            setVersions(data.data);
            setRows(makeRows(data.data));
        }

    },[open, data]);

    const handleRestore = (id) => {
        console.log("restoring version", id);
        const response = axiosInstance.post('company-totals/'+ totalId + '/restore-version', {version_id: id})
        console.log(response);


    }

    const columns = [
        { field: 'total', headerName: 'Total', width: 100 },
        { field: 'status', headerName: 'Status', width: 150, renderCell: (params) => <StatusChip status={params.value}/> },
        { field: 'user', headerName: 'Edited By', width: 100 },
        { 
            field: 'time_updated', 
            headerName: 'Last Edited', 
            type:'date',
            width: 200,
            valueFormatter: (value) => {value.toString()}
        },
        
        { field: 'note', headerName: 'Note', width: 200 },
        { 
            field: 'restore', 
            headerName: '', 
            width: 100,
            renderCell: (params) => (<Button
                                        variant='contained'
                                        size= "small"
                                        onClick={() => {handleRestore(params.row.id)}}>Restore</Button>)
        }
            ]
 
    return (
        <div>

            <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
                <DialogTitle>Total Versions</DialogTitle>
                <DialogContent>
                    {loading && <Box sx={{ display: 'flex', width:1, justifyContent:'center' }}><CircularProgress/></Box>}
                    {!loading && versions && 
                    <div style={{ height: 300, width: '100%' }}>
                        <DataGrid 
                        sx={{height:300}}
                        rows={rows} 
                        columns={columns} 
                        pageSize={5} />
                    </div>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {onClose()}}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default TotalVersions;