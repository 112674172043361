import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
      fontFamily: ['Helvetica']
    },
    palette: {
      primary: {
        main: '#1f409c',
        light:'#a0afdf'
      },
      secondary: {
        main: '#7945a3',
      },
      background: {
        default: '#ffffff',
      },
    },
    typography: {
      
      fontFamily: 'Helvetica',
      fontSize: 14,
    }
      }
    )
  

  export default theme