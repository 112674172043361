

export default function formatDecimal(number) {
    const num = Number(number);
    if (num <1) {
        return `${(num *100).toFixed(2)} %`
    }
    else {
        return `${(num.toFixed(2))} %`
    }
}