import React, {useState, useEffect} from 'react';
import {Grid, TextField, Paper, Autocomplete, Typography} from '@mui/material';
import IndustryOptions from '../autocomplete/IndustryOptions';
import CompanyHistory from '../charts/CompanyHistory';
import CompanyOptions from '../autocomplete/CompanyOptions';

//const MemoizedCompanyHistory = React.memo(CompanyHistory);

export default function CompanyHistoryPage({company, study}) {
    const [industry, setIndustry] = useState(null);
    const [region, setRegion] = useState(null);
    const [segment, setSegment] = useState('World Region');
    const [category, setCategory] = useState(null);
    const [companyCompare, setCompanyCompare] = useState(null);


    useEffect(() => {
        console.log("setting query params")
        if (industry) {
            setSegment('Industry')
            setCategory(industry.name)
        }
        else if (region) {
            setSegment('World Region')
            setCategory(region)
        }

    }, [industry, region])
    return (
        <Grid sx={{marginTop:2}} container spacing={2}>
            <Grid item xs={6}>
                <IndustryOptions value={industry} setIndustry={setIndustry}/>
            </Grid>
            <Grid item xs={6}>
                <Autocomplete
                    options={['Global', 'North America', 'EMEA', 'Asia', 'Latin America']}
                    value={region}
                    onChange={(event, newValue) => {
                        setRegion(newValue)
                        setIndustry(null)
                    }}
                    renderInput={(params) => <TextField {...params} label="Region" />}
                />
            </Grid>
            <Grid item xs={6}>
                <Paper elevation={3} sx={{padding: 2, border:'solid 2px gray'}} >
                    <Typography color="primary" fontStyle="italic" variant="body1"> Add another supplier for comparison</Typography>
                    <CompanyOptions company={companyCompare} setCompany={setCompanyCompare} study={study}/>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <CompanyHistory company={company} study={study} segment={segment} category={category} companyCompare={companyCompare}/>
            </Grid>
        </Grid>
    )
}