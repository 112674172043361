import {useState, useEffect} from 'react'
import { Box, FormControl,FormLabel, FormGroup, FormHelperText, FormControlLabel, Checkbox } from "@mui/material";
import useAPI from "../../hooks/useAPI";


export default function StudyMultiSelect({studies, setStudies}) {
    const [studyOptions, setStudyOptions] = useState([])
    const {data, error, loading} = useAPI('studies');
    const [checked, setChecked] = useState({})

    useEffect(() => {
        if (data) {
            setStudyOptions(data.data)
            data.data.map((item) => {checked[item.name]= false})
            
        }
    },[data])
  
    const handleChange = (event) => {
        setChecked({
          ...checked,
          [event.target.name]: event.target.checked,
        });
      };
    useEffect(() => {
        setStudies(Object.keys(checked).filter(key => checked[key]===true))
    }, [checked])
    
    return (
    <Box sx={{ display: 'flex' }}>
      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormLabel component="legend">Filter By Study</FormLabel>
        <FormGroup>
        
        {studyOptions && (!loading) && studyOptions.map( study => 
            <FormControlLabel
                control={
                  <Checkbox checked={checked[study.name]} onChange={handleChange} name={study.name} />
                }
                label={study.name}
              />
            )}
          
        </FormGroup>
      </FormControl>
      </Box>
    )
}