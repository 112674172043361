import {useState, useEffect} from 'react'
import useAPI from '../../hooks/useAPI'
import {Autocomplete, TextField} from '@mui/material'


export default function StudyOptions({setStudy, study, disabled}) {
    const {data, error, loading} = useAPI('/studies')
    const [studies, setStudies] = useState([])

    useEffect(() => {
        if (data) {
            setStudies(data.data)
        }
    }, [data])

    return (
        <Autocomplete
            options={studies}
            disabled={disabled}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
                setStudy(newValue);
            }}
            value={study}
            renderInput={(params) => <TextField {...params} label="Study" />}
            fullWidth/>
        
    )
}