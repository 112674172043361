import * as React from 'react';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import formatCurrency from '../../../utils/formatCurrency';


function valuetext(value) {
    return `${value}%`;
  }
const Input = styled(MuiInput)`
  width: 70px;
`;

const ModellingSlider = ({modelledGrowth, totalRevenue, newRevenue, setNewRevenue}) => {
  
  const [value, setValue] = React.useState(modelledGrowth);
  
  useEffect(() => {
    if (modelledGrowth !== null) {
      setValue(modelledGrowth)
    }
  },[modelledGrowth])

  useEffect(() => {
    if (totalRevenue !== null) {
      setNewRevenue(((1+(modelledGrowth/100))*totalRevenue).toFixed(2));
    }
  },[totalRevenue, modelledGrowth])

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    setNewRevenue(((1+(value/100))*totalRevenue).toFixed(2))
    console.log("calculated revenue", newRevenue)
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? 0 : Number(event.target.value));
    setNewRevenue(((1+(event.target.value/100))*totalRevenue))
    
    console.log("calculated revenue", newRevenue)
    
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };

  return (
    <Box key={modelledGrowth} width={.8}>
      <Typography sx={{marginLeft:5}} variant='h6' color='primary' id="input-slider" gutterBottom>
        Adjust Supplier Growth
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>

          <Slider
            aria-label="Small steps"
            
            value= {value}
            getAriaValueText={valuetext} 
            step={0.25}
            marks
            min={modelledGrowth-modelledGrowth}
            max={modelledGrowth+modelledGrowth}
            valueLabelDisplay="auto"
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            />
        </Grid>
        <Grid item>
          <Input
            value={value}
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 10,
              min: 0,
              max: 100,
              type: 'number',
              'aria-labelledby': 'input-slider',
              endAdornment: ("%")
                }}
            endAdornment= {<Typography> %</Typography>}
            
          />
        </Grid>
        <Grid item sx={{ marginLeft:"8vh"}}>
            <Typography color='primary'  variant={"subtitle1"}> Modelled Market: <b>{formatCurrency(newRevenue)} mil. </b></Typography>
            <Typography color='#737980' variant={"subtitle2"}> Previous Total: <b>{formatCurrency(totalRevenue)} mil.</b></Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
export default ModellingSlider