
import {useState, useEffect, useMemo} from 'react';
import {Box, CircularProgress, Container} from '@mui/material';
import axiosInstance from '../../axiosConfig';
import { LineChart } from '@mui/x-charts';


const Myconfig = {
    type: 'line',
    data: {
        labels: [],
        datasets: [],
    },
    options: {
        maintainAspectRatio: false,
        layout: {
        padding: {
            left: 10,
            right: 25,
            top: 25,
            bottom: 0
        }
        },
    }
    };
const Mydataset = {
    label: null,
    lineTension: 0.3,
    backgroundColor: "rgba(78, 115, 223, 0.05)",    
    borderColor: "rgba(78, 115, 223, 1)",
    pointRadius: 3,
    pointBackgroundColor: "rgba(78, 115, 223, 1)",
    pointBorderColor: "rgba(78, 115, 223, 1)",
    pointHoverRadius: 3,
    pointHoverBackgroundColor: "rgba(78, 115, 223, 1)",
    pointHoverBorderColor: "rgba(78, 115, 223, 1)",
    pointHitRadius: 10,
    pointBorderWidth: 2,
    data: null
        }
export default function CompanyHistory({company, study, segment, category, companyCompare}) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [config, setConfig] = useState({...Myconfig});
    const [series, setSeries] = useState([])
    const [primaryData, setPrimaryData] = useState({label:"", data:[]});
    const [secondaryData, setSecondaryData] = useState(null);
    const [labels, setLabels] = useState(null);

    const params = useMemo(() => {
        const searchParams = new URLSearchParams();
        searchParams.append('company_id', company.id);
        searchParams.append('study_id', study.id);
        if (segment) {
            searchParams.append('segment_name', segment);
        }
        if (category) {
            searchParams.append('category_name', category);
        }
        return searchParams;
    }, [company.id, study.id, segment, category]);

    const fetchData = async () => {
        try {
            console.log("fetching company history")
            setLoading(true)
            setSeries([])
            const response = await axiosInstance.get('historic-totals', {params:params})
            const  years = response.data.map(entry => entry.year)
            const marketSize = response.data.map(entry => entry.market_size)
            setPrimaryData({data:marketSize, label:company.name})
            setLabels(years)

            } catch (error){
            console.log("error fetching company history", error )
            setError(error.response.data.message)
        }
        setLoading(false)
    }
    const fetchCompareData = async () => {
        console.log("fetching compare data", companyCompare)
        const searchParams = new URLSearchParams();
        searchParams.append('company_id', companyCompare.id);
        searchParams.append('study_id', study.id);
        if (segment) {
            searchParams.append('segment_name', segment);
        }
        if (category) {
            searchParams.append('category_name', category);
        }
        try {
            const response = await axiosInstance.get('historic-totals', {params:searchParams})
            console.log("fetched compare data", response.data) 
            const marketSize = response.data.map(entry => entry.market_size)
            setSecondaryData({data:marketSize,label: companyCompare.name})
            console.log("series after compare",series)
            
        } catch (error){
            console.log("error fetching company history", error )
            setError(error.response.data.message)
        }
    }

    useEffect(() => {
        fetchData()
        if (companyCompare) {
            fetchCompareData()
        }
        
    }, [params, segment, category, companyCompare]);

    return (
        <Container width={1} sx={{height:600}}>
            {loading &&  <Box sx={{ display: 'flex', width:1, justifyContent:'center' }}><CircularProgress/></Box>}
            {!loading && error && <div>Error loading data</div>}
            {!loading && !error &&
            <LineChart 
                xAxis={[{ data: labels, valueFormatter:(value) => `${value}`}]} 
                yAxis={[{min:0}]}
                series={secondaryData?[primaryData, secondaryData]:[primaryData]}
                //dataset={series}
                grid={{ vertical: true, horizontal: true }} />}
        </Container>
    );
}