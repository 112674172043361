import {useState, useEffect} from 'react'
import {Grid, Paper, Stack, Typography} from '@mui/material'
import TotalActions from './TotalActions'
import numberWithCommas from '../../../utils/formatNumber'
import ActionsButton from '../../buttons/ActionsButton'

export default function TotalCard({company, study, companyTotal}) {
    const [menuOpen, setMenuOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [growth, setGrowth] = useState(null)
    const handleMenuOpen = (event) => {
        console.log(event.currentTarget)
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
      };
    
      const handleMenuClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
      };

    useEffect(() => {
        if (companyTotal.total_previous_year) {
        const g = (((companyTotal.revenue-companyTotal.total_previous_year.revenue)/companyTotal.total_previous_year.revenue)*100).toFixed(2)
        setGrowth(g)
        }
    },[companyTotal])


    return (
        <Grid item xs={4}>
            <Paper elevation={3} sx={{border: 'solid 1px black'}}>
                <Stack>
                    <Typography variant='caption' color='#919a9e'>
                        Year: {companyTotal.year} Quarter: {companyTotal.quarter}
                    </Typography>
                    <Typography variant='h5' color='primary'>
                        Total: $ {numberWithCommas(companyTotal.revenue.toFixed(2))} {growth && <span><b> (+ {growth} %) </b></span> }
                    </Typography>
                    <Typography variant='caption' color='#919a9e'>
                        Status:  {companyTotal.status}
                    </Typography>
                </Stack>
                <ActionsButton key={companyTotal.id} handleClick={handleMenuOpen} buttonType={'full'}/>
                <TotalActions open={menuOpen} onClose={handleMenuClose} anchorEl={anchorEl} company={company} study={study} total={companyTotal} growth={growth}/>
            </Paper>
        </Grid>
    )
}