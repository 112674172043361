import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import useAPI from "../../hooks/useAPI"
import {Grid, Container, CircularProgress, Stack, Typography, Paper, Button} from '@mui/material'


export default function IndustryDashboard() {
    const {data, error, loading} = useAPI('/industries')
    const [industries, setIndustries] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        if (data) {
            setIndustries(data.data)
        }
    }, [data])

    return (
        <Grid>
            <Container>
                <Typography variant="h4" color='primary'>Industry Dashboard</Typography>
                {loading && <CircularProgress />}
                {error && <Typography>Error fetching industries</Typography>}
                <Stack  spacing={3}> 
                {industries && industries.map(industry => 
                    <Paper key={industry.id} elevation={3}>
                        <Typography key={industry.id}>{industry.name}</Typography>
                        <Button onClick={() => {navigate('/industry-detail/'+industry.name)}}>View</Button>
                    </Paper>)}
                </Stack>
            </Container>
        </Grid>
        )
    }
