import React from 'react'

import {Bar} from 'react-chartjs-2'


const options = {
    maintainAspectRatio: false,

    
    legend: {
    display: false
    },
    tooltips: {
    titleMarginBottom: 10,
    titleFontColor: '#6e707e',
    titleFontSize: 14,
    backgroundColor: "rgb(255,255,255)",
    bodyFontColor: "#858796",
    borderColor: '#dddfeb',
    borderWidth: 1,
    xPadding: 15,
    yPadding: 15,
    displayColors: false,
    caretPadding: 10,
    callbacks: {
        label: function(tooltipItem, chart) {
        var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
        return datasetLabel + ': $' + tooltipItem.yLabel;
        }
    }
    },
}



export default function BarChart({chartData}) {
    console.log(chartData)
    return (
        <div className='chart-container' style={{height:'400px'}}> 
        <Bar
            options={options}
            data = {chartData}
        > </Bar>
        </div>
    )
}

