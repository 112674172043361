export default function splitData(data, group_by)  {
    const groups = [...new Set(data.map((d) => d[group_by]))]
    
    const yearOne = data.filter((d) => d.year == 2023)
    const yearTwo = data.filter((d) => d.year == 2024)

    const mapped = groups.map((groupItem) =>  {
        const first = yearOne.filter((item) => item[group_by]===groupItem)[0];
        const second = yearTwo.filter((item) => item[group_by]===groupItem)[0];
            return ({revenue23: first.revenue,
                        share23: second.market_share,
                        concentration23: first.concentration,
                        revenue24: second.revenue,
                        share24: second.market_share,
                        concentration24: second.concentration,
                        shareYoY: ((second.market_share-first.market_share)/first.market_share),
                        revenueYoY: ((second.revenue-first.revenue)/first.revenue),
                        id: groupItem,
                        name: groupItem,
                        other: null
                    }
                    )})
            
    return ({groups: groups, mapped:mapped})
                }