import {Grid, Typography, Stack, Paper} from '@mui/material'
import formatCurrency from '../../../../utils/formatCurrency'
import formatDecimal from '../../../../utils/formatDecimal'

export default function StatsGrid({stats}) {


    return (
        <Grid container spacing={1}>
            <Grid item xs={3}>
                <Paper elevation={3} sx={{border:'3px solid #1f409c',borderRadius:2, margin:2, height:80, width:140}}>
                    <Stack sx={{marginTop:2}}spacing={1}>
                        <Typography color='primary' fontWeight={'bold'} variant='body2'> {formatCurrency(stats.totalCurrentYear)} mil.</Typography>
                        <Typography variant='caption'> Current Market</Typography>
                    </Stack>  
                </Paper>
            </Grid>
            <Grid item xs={3}>
                <Paper elevation={3} sx={{border:'3px solid #1f409c',borderRadius:2, margin:2, height:80, width:140}}>
                    <Stack sx={{marginTop:2}}spacing={1}>
                        <Typography color='primary' fontWeight={'bold'} variant='body2'> {formatCurrency(stats.totalLastYear)} mil.</Typography>
                        <Typography variant='caption'> Prev Year Market</Typography>
                    </Stack>  
                </Paper>
            </Grid>
            <Grid item xs={3}>
                <Paper elevation={3} sx={{border:'3px solid #1f409c',borderRadius:2, margin:2, height:80, width:140}}>
                    <Stack sx={{marginTop:2}}spacing={1}>
                        <Typography color='primary' fontWeight={'bold'} variant='body2'> {formatDecimal(stats.totalGrowth)} mil.</Typography>
                        <Typography variant='caption'> Market Growth</Typography>
                    </Stack>  
                </Paper>
            </Grid>
            <Grid item xs={3}>
                <Paper elevation={3} sx={{border:'3px solid #1f409c',borderRadius:2, margin:2, height:80, width:140}}>
                    <Stack sx={{marginTop:2}}spacing={1}>
                        <Typography color='primary' fontWeight={'bold'} variant='body2'> {formatDecimal(stats.averageGrowth)} mil.</Typography>
                        <Typography variant='caption'> Avg. Supplier Growth</Typography>
                    </Stack>  
                </Paper>
            </Grid>
            
        </Grid>
    )
}