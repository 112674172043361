import * as React from 'react';
import { useState, useEffect } from 'react';
import {Tabs, Tab, Box, CircularProgress, Grid, Typography, Container} from '@mui/material';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../axiosConfig';
import { useSnackbar } from '../../context/SnackbarContext';
import Totals from '../company_study/totals/Totals'
import CompanyHistoryPage from '../company_study/CompanyHistoryPage';
import DisplayDifferentiators from '../differentiator/DisplayDifferentiators';
import Concentrations from '../company_study/concentrations/Concentrations';
import SegmentYearOnYear from '../company_study/year_over_year/SegmentYearOnYear';


const TabPanel = ({value, company, study}) => {

  switch(value) {
    case 'one':
      return <Totals company={company} study={study}/>
    case 'two':
      return <Concentrations company={company} study={study}/>
    case 'three':
      return <CompanyHistoryPage company={company} study={study}/>
    case 'four':
      return <SegmentYearOnYear company={company} study={study}/>
    case 'five':
      return <DisplayDifferentiators company={company} study={study}/>
    case 'six':
      return <div>Products</div>
    default:
      return <div>Overview</div>
  }
}
export default function StudyCompanyPage() {
  const [value, setValue] = React.useState('one');
  const {openSnackbar} = useSnackbar();
  const [loading, setLoading] = useState(true);
  const {companyName, studyName} = useParams();
  const [company, setCompany] = useState(null);
  const [study, setStudy] = useState(null);

  useEffect(() => {
    const fetchCompany = async () => {
        try {
            console.log("fetching company")
            setLoading(true)
            const response = await axiosInstance.get('/companies?name='+companyName)
            setCompany(response.data.data[0])
            setLoading(false)
        } catch (error){
            console.log("error fetching company from url params.", error )
            openSnackbar(error.response.data.message, 'error')
    }}

    
    fetchCompany()

},[companyName]
)
  useEffect(() => {
    const fetchStudy = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.get('/studies/search',{params: {"name":studyName}})
            setStudy(response.data.data)
            setLoading(false)
        } catch (error){
            console.log("error fetching study from url.", error )
            openSnackbar(error.message, 'error')
            setLoading(false)
    }}
    if (!study) {
      fetchStudy()
    }
        
  },[])


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  if (!study && !company) {
    return (<Box sx={{ display: 'flex', width:1, justifyContent:'center' }}><CircularProgress/></Box>)
  }
  else { 
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom color='primary'>
          {studyName}:
        </Typography>
        <Typography variant="h4" fontWeight={'bold'} gutterBottom color='primary'>
          {companyName}
          
        </Typography>
      </Grid>
      <Grid company={company} item xs={12}>
        <Box sx={{ width: '100%', justifyContent: 'center', alignItems:'center', display:'flex' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            <Tab
              value="one"
              label="Total"
            />
            <Tab value="two" label="Segmentation" />
            <Tab value="three" label="History" />
            <Tab value="four" label="Year over Year" />
            <Tab value="five" label="Differentiators" />
            <Tab value="six" label="Products" />
          </Tabs>

        </Box>
      </Grid>
      <Grid item xs={12}>
      {loading &&  <Box sx={{ display: 'flex', width:1, justifyContent:'center' }}><CircularProgress/></Box>}
      {company && study && <Container> <TabPanel value={value} company={company} study={study}/></Container>}
      </Grid>
    </Grid>
  );
}}
