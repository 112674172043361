import { useState, useEffect } from "react";
import { Typography,
        Grid, 
        DialogTitle,
        DialogActions,
        DialogContent,
        Divider,
        Stack,
        Button, 
        Dialog } from '@mui/material';
import useAPI from '../../hooks/useAPI';
import splitData from '../../utils/splitData'

export default function SegmentDetail({open, onClose, company, study, differentiator}) {
    const [concentration, setConcentration] = useState(null);
    const [concentrationPrev, setConcentrationPrev] = useState(null);
    const params = new URLSearchParams();
    params.append('company_id', company.id);
    params.append('study_id',differentiator.study_id)
    params.append('company_id', differentiator.company_id)
    params.append('industry_id',differentiator.industry? differentiator.industry.id: null)
    //params.append('year', 2024)
    const { data, error, loading } = useAPI('concentrations', {params: params}, 'Error fetching segment detail');

    useEffect(() => {
        if (data) {
            const split = splitData(data.data, 'company_name')
            console.log("split in diff modal", split)
            setConcentration(split.mapped[0])
        }
    },[data])
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle><Typography variant='h5' color='primary' textAlign={'center'}> Segment Detail</Typography></DialogTitle>
            <DialogContent>
            <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
                {loading && <Grid item xs={12}><Typography>Loading...</Typography></Grid>}
                {error && <Grid item xs={12}><Typography>{error}</Typography></Grid>}
                {concentration &&
                <>
                    <Grid item xs={6}>
                        <Typography color={'primary'} fontWeight={'bold'} textAlign={'center'}> 2023</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color={'primary'} fontWeight={'bold'} textAlign={'center'}> 2024</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack>
                            <Typography> Revenue: $ {concentration.revenue23.toFixed(2)}</Typography>
                            <Typography> Concentration: {(concentration.concentration23*100).toFixed(2)} %</Typography>
                            <Typography> Share: {(concentration.share23*100).toFixed(2)} % </Typography>
                        </Stack>
                        <Divider orientation="vertical"> </Divider>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack>
                            <Typography> Revenue: $ {concentration.revenue24.toFixed(2)}</Typography>
                            <Typography> Concentration: {(concentration.concentration24*100).toFixed(2)} %</Typography>
                            <Typography> Share: {(concentration.share24*100).toFixed(2)} % </Typography>
                        </Stack>
                    </Grid>
                    
                </>}
            </Grid>
            
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { console.log("allcompany data")}}>All Company Data</Button>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )

}
