import {useState, useEffect} from 'react'
import useAPI from '../../hooks/useAPI'
import {Autocomplete, TextField} from '@mui/material'


export default function IndustryOptions({setIndustry, industry, disabled}) {
    const {data, error, loading} = useAPI('/industries')
    const [industries, setIndustries] = useState([])

    useEffect(() => {
        if (data) {
            setIndustries(data.data)
        }
    }, [data])

    return (
        <Autocomplete
            options={industries}
            disabled={disabled}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
                setIndustry(newValue);
            }}
            value={industry}
            renderInput={(params) => <TextField {...params} label="Industry" />}
            fullWidth/>
        
    )
}