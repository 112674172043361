import { useState, useEffect} from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';
import PieChart from './basecharts/PieChart';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import createCompanyChart from '../../utils/createCompanyChart';
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import useAPI from '../../hooks/useAPI';

Chart.register(CategoryScale);
  


export default function CompanyChart({company}) {
    const {data, error, loading} = useAPI('company-totals', {params: {company_id: company.id, year:2024}});
    const [chartData, setChartData] = useState(createCompanyChart([]));
    useEffect(() => {
        if (data) { 
        console.log("chart data", data.data);
        setChartData(createCompanyChart(data.data));
        }
    }, [data]) 
    console.log(chartData)
    return (

        <Box marginTop= {5} marginBottom={5} width={1}>
             {loading && <Box sx={{width:1,marginTop:10, display:'flex', justifyContent:'center', alignItems:'center'}}> <CircularProgress/> </Box>}
             { error && <Box sx={{width:1,marginTop:10, display:'flex', justifyContent:'center', alignItems:'center'}}> <Typography sx={{width:1}}> Error loading data </Typography><ErrorOutlineIcon/> </Box>}
             {(!loading) && 
            <PieChart id={company.name} chartData = {chartData}/>
             }
        </Box>      
);
}


