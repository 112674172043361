import {useState, useEffect} from 'react'
import { Button, Dialog, IconButton, DialogTitle, DialogActions, DialogContent, Typography } from "@mui/material"
import CompanyOptions from '../../autocomplete/CompanyOptions'
import CloseIcon from '@mui/icons-material/Close'
export default function MergeModal({open, onClose, company, study}) {
    const [mergeTarget, setMergeTarget] = useState(null)
    return (
        
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                <Typography variant={'h6'} fontWeight='bold' color='primary' textAlign={'center'}> Select Acquisition Target for {company.name}</Typography>
            </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <CloseIcon />
                </IconButton>
            <DialogContent>
                    <Typography> Select a Company to merge.</Typography>
                    <CompanyOptions study={study} company={mergeTarget} setCompany={setMergeTarget}/>
            </DialogContent>
            <DialogActions>
                <Button>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}