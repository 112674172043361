import { useState, useEffect} from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';
import BarChart from './basecharts/BarChart';
import PieChart from './basecharts/PieChart';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import createDataset from '../../utils/charts';
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import useAPI from '../../hooks/useAPI';

Chart.register(CategoryScale);

export default function ConcentrationChart({study, company, segment}) {
    const params = new URLSearchParams();
    params.append('study_id', study.id)
    params.append('company_id', company.id)
    params.append('year', 2024)
    params.append('segment', segment)
    const [chartData, setChartData] = useState(createDataset([]));
    const {data, error, loading} = useAPI('concentrations', {params: params});
    useEffect(() => {
        if (data) { 
        setChartData(createDataset(data.data));
        }
    }, [data]
        ) 
    if (chartData.labels.length > 4) {
        return (
            <Box marginTop= {5} marginBottom={5} width={1}>
            {loading && <Box sx={{width:1,marginTop:10, display:'flex', justifyContent:'center', alignItems:'center'}}> <CircularProgress/> </Box>}
            { error && <Box sx={{width:1,marginTop:10, display:'flex', justifyContent:'center', alignItems:'center'}}> <Typography sx={{width:1}}> Error loading data </Typography><ErrorOutlineIcon/> </Box>}
            {(!loading) && 
           <BarChart id={company.name} chartData = {chartData}/>
            }
       </Box>
        )}
    else {
        return (

                <Box marginTop= {5} marginBottom={5} width={1}>
                     {loading && <Box sx={{width:1,marginTop:10, display:'flex', justifyContent:'center', alignItems:'center'}}> <CircularProgress/> </Box>}
                     { error && <Box sx={{width:1,marginTop:10, display:'flex', justifyContent:'center', alignItems:'center'}}> <Typography sx={{width:1}}> Error loading data </Typography><ErrorOutlineIcon/> </Box>}
                     {(!loading) && 
                    <PieChart id={company.name} chartData = {chartData}/>
                     }
                </Box>
                
    );}
}