
function createDataset(records) {

    return (
            {labels: records.map(record => record.category), 
            datasets: [
                {
                    label: "Concentration",
                    data: records.map(record => record.concentration),
                    backgroundColor: ['#4e73df', '#1cc88a', '#36b9cc', '#f6c23e'],
                    hoverBackgroundColor: ['#2e59d9', '#17a673', '#2c9faf', '#d6b45e'],
                    hoverBorderColor: "rgba(234, 236, 244, 1)",
                  }
                ]
            }
            )
}

export default createDataset