import React, { useEffect, useState } from 'react';
import {Container, Stack, InputLabel, TextField, Autocomplete } from '@mui/material';

import useAPI from '../../hooks/useAPI';


export default function SegmentOptions({study, segment, setSegment}) {
    const {data, error, loading} = useAPI('studies/'+study.id+'/segments')
    const [segments, setSegments] = useState([]);
    
    
    useEffect(() => {
        if (data) {
            console.log("segment options", data.data)
            setSegments(data.data.map((d) => ({label: d.name, name:d.name})))
        }
    },[data,study])

    return (
       
        <Container sx={{marginBotton:1}}>
            <Stack>
                <InputLabel id="segment-label">Segment</InputLabel> 
                <Autocomplete 
                disablePortal
                id="combo-box-demo"
                options ={segments}
                fullWidth
                onChange={(event, newValue) => setSegment(newValue)}
                renderInput ={(params) => <TextField {...params} label="Segment"/> }>
                
                </Autocomplete>
            </Stack>
        </Container>     
);
}