
import {useState, useEffect} from 'react';

import useAPI from '../../hooks/useAPI';

import {Grid,
        Stack,
        Button,
        CircularProgress,
        Typography,
        Fab,
        Box} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import DifferentiatorModal from './DifferentiatorModal';
import DifferentiatorBar from './DifferentiatorBar';
import SegmentDetail from '../company/SegmentDetail';
import ConfirmDelete from './ConfirmDelete';

export default function DisplayDifferentiators({study, company, industry}) {
    console.log("industry", industry)
    const [differentiators, setDifferentiators] = useState([]);
    const [reloadFlag, setReloadFlag] = useState(false);
    const params = new URLSearchParams();
    const disabled = [];
    if (study) {
        params.append('study_id', study.id)
        disabled.push('Study')
    }
    if (company) {
        params.append('company_id', company.id)
        disabled.push('Company')
    }
    if (industry) {
        params.append('industry_id', industry.id)
        disabled.push('Industry')
    }
    const {data, error, loading} = useAPI('/differentiators', {params:params}, 'Error fetching differentiators', [reloadFlag]);
    const [openEditor, setOpenEditor] = useState(false);
    const [openDetail, setOpenDetail] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [selectedDifferentiator, setSelectedDifferentiator] = useState(null);

    const triggerReload = () => {
        console.log("triggering reload")
        setReloadFlag(!reloadFlag)
    }
    const handleClose = () => {
        setOpenEditor(false)
        setSelectedDifferentiator(null)
        
    }
    const handleDetailClose = () => { 
        setOpenDetail(false)
        setSelectedDifferentiator(null)
    }
    const clickOpenDetail = (differentiator) => {
        setSelectedDifferentiator(differentiator)
        setOpenDetail(true)
    }     
    const handleDeleteClose = () => {
        setOpenDelete(false)
        setSelectedDifferentiator(null)
    }
    
    const openModal = (differentiator) => {
        setSelectedDifferentiator(differentiator)
        console.log("opening modal")
        console.log("selected differentiator", differentiator)
        setOpenEditor(true)
    }
    const clickOpenDelete = (differentiator) => {
        setSelectedDifferentiator(differentiator)
        setOpenDelete(true)
    }

    useEffect(() => {
        if (data) {
            setDifferentiators(data.data)
            setReloadFlag(false)
        }
    }  
    ,[data, reloadFlag])

    return (
            
            <> 
            <Grid refresh={reloadFlag} justifyContent={'center'} alignItems={'center'} container spacing={2}>

                {(loading || reloadFlag) && <Box sx={{width:1, marginTop:5, display:'flex', justifyContent:'center', alignItems:'center'}}> <CircularProgress/> </Box>}
                {error && <Box sx={{width:1, display:'flex', justifyContent:'center', alignItems:'center'}}> <Typography>Error fetching differentiators</Typography> </Box>}

                {differentiators.map((differentiator) => (
                    <Grid item xs={7} >
                        <DifferentiatorBar id={differentiator.id} differentiator={differentiator} openModal={openModal} clickOpenDetail={clickOpenDetail} clickOpenDelete={clickOpenDelete}/>
                    </Grid>
                   
                ))}
            </Grid>
            
            <DifferentiatorModal open={openEditor} onClose={handleClose} study={study} company={company} industry={industry} differentiator={selectedDifferentiator} disabled={disabled} triggerReload={triggerReload} />
            {selectedDifferentiator && openDetail && 
                <SegmentDetail open={openDetail} onClose={handleDetailClose} study={study} company={company} differentiator={selectedDifferentiator} />}
            {selectedDifferentiator && openDelete &&
                <ConfirmDelete open={openDelete} onClose={handleDeleteClose} differentiator={selectedDifferentiator} triggerReload={triggerReload}/>}
                  <Fab sx={{position:'absolute', bottom:50, right:100}}
                    color="primary"
                    aria-label="add"
                    onClick={() => {setOpenEditor(true)}}>
                    <AddIcon />
                </Fab>
            </>
    )
} 