
import { useState,useEffect } from 'react';
import { Grid } from '@mui/material';

import Loading from '../../../loading/Loading'
import useAPI from '../../../../hooks/useAPI'
import CategoryPanel from  './CategoryPanel'

export default function MarketAnalysisAccordion({study, segment}) {
    const [reloadFlag, setReloadFlag] = useState(false)
    console.log("segment", segment)
    const {data, error, loading} = useAPI('studies/'+study.id +'/segments/' + segment.name + '/concentrations',{}, "failed to load concentrations", [reloadFlag, segment])
    const [expanded, setExpanded] = useState(false);
    const [year, setYear] = useState(2024)
    const [categories, setCategories] = useState(null);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        };  
    const forceReload = () => {
        setReloadFlag(!reloadFlag)
    }

    
    useEffect(() => {
        if (data) {
            setCategories(data.data.sort((a,b) => b.revenue-a.revenue))
        }
    },[data, reloadFlag, segment])

    return (

        <Grid container spacing={1} sx={{width:1}}>
            {loading && <Loading/>}
                <Grid item xs={12}>
                {categories &&
                    categories.map((category) => 
                            <CategoryPanel segment={segment} study={study} category={category} year={year} handleChange={handleChange} expanded={expanded} forceReload={forceReload}/>
                        )
                }
                     
                </Grid>
        </Grid>
        )}