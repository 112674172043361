function numberWithCommas(x) {
    
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export default function formatCurrency(number) {
    const num = Number(number);
    const toString = num.toFixed(2);
    var parts = toString.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const x =  parts.join(".");
    return `$ ${x}`
}