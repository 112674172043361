import {Button,IconButton } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function ActionsButton({ buttonType, handleClick}) {
        switch (buttonType) {
         case "full":
           return  (<Button
               onClick={handleClick}
               endIcon={<KeyboardArrowDownIcon />}
               >
           Options
         </Button>)
         case "slim":
           return (
             <IconButton
               onClick={handleClick}
             >
             <MoreVertIcon />
           </IconButton>
           )
        } 
     }
