
import FactoryIcon from '@mui/icons-material/Factory';
import PublicIcon from '@mui/icons-material/Public';
import ConstructionIcon from '@mui/icons-material/Construction';
import ManIcon from '@mui/icons-material/Man';
import ReceiptIcon from '@mui/icons-material/Receipt';

export default function SegmentChip({segment}) {
    
    switch(segment) {
        case 'World Region':
            return (<PublicIcon color='primary'/>)
        
        case 'Industry':
           return (<FactoryIcon color='primary'/> )
        
        case 'Application Type':
            return (<ConstructionIcon color='primary'/>)
        
        case 'Customer Type':
            return (<ManIcon color='primary'/>)

        case 'Sales Channel':
            return (<ReceiptIcon color='primary'/>)
    }

}