import React, {useState, useEffect} from 'react'
import useAPI from "../../hooks/useAPI"
import { useNavigate } from 'react-router-dom'
import {Grid, Container, CircularProgress,Box, TextField, Button, Typography, Paper, Stack, Divider} from '@mui/material'
import StudyMultiSelect from '../multiselect/StudyMultiSelect'


const searchCompanies = (companies,searchTerm) => {
    console.log("searching companies")
    if (companies.length>1) {
        
        return companies.filter((company) => 
            company.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
} else {
    return ( [])
}
}

const filterbyStudy = (companies, studyArray) => {
    if (studyArray.length>=1) {
        const filteredbyStudy = companies.filter((company) =>  
            studyArray.every(studyName=> company.studies.some(study => study.name== studyName))
        )
        console.log("filtered by study", filteredbyStudy)
        return (filteredbyStudy)
    }
    else {
        return(companies)
    }
}

export default function CompanyDashboard() {
    const {data, error, loading} = useAPI('/companies')
    const [companies, setCompanies] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [filteredCompanies, setFilteredCompanies] = useState([])
    const [studies, setStudies] = useState([])

    useEffect(() => {
        console.log("studies changed", studies)
    }, [studies])

    const navigate = useNavigate()
    useEffect(() => {
        if (data) {
            setCompanies(data.data)
        }
    }, [data])

    useEffect(() => {
        if (searchTerm){ 
            setFilteredCompanies(searchCompanies(companies, searchTerm))
        }
        
    },[companies, data, searchTerm])

    useEffect(() => {
    if (studies) {
        setFilteredCompanies(filterbyStudy(companies, studies))
    }
},[companies, data,  studies])

    const handleSearchChange = (event) => {
        console.log(searchTerm)
        setSearchTerm(event.target.value);
      };
    return (
        <Grid>
            <Container>
                <Typography variant="h4" color="primary">Company Dashboard</Typography>
                <Box sx={{ width: 1, margin:2 }}>
                    <TextField
                        sx= {{width:0.8}}
                        label="Search by company name"
                        variant="outlined"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        margin="normal"
                    />
                </Box>
                <StudyMultiSelect studies={studies} setStudies={setStudies}/>
                {loading && <CircularProgress />}
                {error && <Typography>Error fetching companies</Typography>}
                <Paper sx={{margin:5}} elevation={3}>
                {filteredCompanies && filteredCompanies.map(company => 
                <Grid container justifyContent={'center'} alignItems={'center'} spacing={2}> 
                    <Grid item xs={12}>
                        <Stack direction={'horizontal'} spacing={10} sx={{margin:2, marginLeft:4}} alignItems={'center'}>
                            <Typography key={company.id} color='primary' variant='h6' sx={{marginLeft:2, width:0.9}} align={'left'}>{company.name}</Typography>
                            <Divider orientation={"vertical"}/>
                            <Button variant={'contained'} onClick={() => {navigate('/supplier-detail/'+company.name)}}>View</Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={10}>
                        <Divider />
                    </Grid>
                </Grid>)}
                </Paper>
            </Container>
        </Grid>
        )
    }
