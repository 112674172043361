import React from 'react';
import { Paper, Menu,Stack, MenuItem, Divider, Typography, Grid, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const DifferentiatorBar = ({differentiator, openModal, study, company, clickOpenDetail, clickOpenDelete}) => {
    console.log('Props:', {differentiator, openModal, study, company, clickOpenDetail});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const industry = differentiator.industry? differentiator.industry.name: '';
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
      };
    

    return (
        <Paper width={.6} elevation={4} sx={{border:"2px solid #1f409c"}}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant='h5' color='primary'>{differentiator.company.name}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='body1' color='primary'>{differentiator.study.name}</Typography>
            </Grid>
                <Grid item xs={8}>
                    
                </Grid>
                <Grid item xs={4}>
                <Typography variant='body2' fontStyle={'italic'}>{differentiator.time_updated}</Typography>
                </Grid>
                <Grid item xs={8}>
                    
                    </Grid>
                    <Grid item xs={4}>
                    <Typography variant='body2' fontStyle={'italic'}>{industry}</Typography>
                    </Grid>
                <Grid item xs={12}>
                    <div dangerouslySetInnerHTML={{ __html: differentiator.text }} />
                </Grid>
                <Grid item xs={11}/>
                <Grid item xs={1}>
                    <div>
                        <IconButton onClick={handleClick}>
                            <MoreVertIcon />
                        </IconButton>
                    </div>
                    <Menu 
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}>
                        <MenuItem onClick={() => {openModal(differentiator)}}>Edit</MenuItem>
                        <MenuItem onClick={() => {clickOpenDetail(differentiator)}}>Data</MenuItem>
                        <MenuItem onClick={() => {clickOpenDelete(differentiator)}}>Delete</MenuItem>
                        <MenuItem >View Versions</MenuItem>
                    </Menu>
                </Grid>
            </Grid>

        </Paper>
    );
};


export default DifferentiatorBar;