import React, { useState } from 'react';
import {Popper, Paper, MenuItem, ClickAwayListener} from '@mui/material';

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import EditIcon from '@mui/icons-material/Edit';
import ConcentrationEditor from '../../../../company_study/concentrations/ConcentrationEditor'
import CompanyCategoryHistory from '../../../../company_study/concentrations/CompanyCategoryHistory'

const ConcentrationActions = ({ anchorEl, open, onClose, company, study, segment, category, forceReload }) => {
    const [openEditor, setOpenEditor] = useState(false);
    const [openHistory, setOpenHistory] = useState(false);

    const handleClose = () => {
        setOpenEditor(false)
        setOpenHistory(false)
      }
    return (
<>
  <Popper key={company.name} open={open} anchorEl={anchorEl} disablePortal placement={'left-start'}>
    <ClickAwayListener onClickAway={onClose}>
      <Paper>
        <MenuItem onClick={setOpenEditor}> <EditIcon sx={{marginRight:2}}/> Edit Concentrations</MenuItem>
        <MenuItem onClick={setOpenHistory}><QueryStatsIcon sx={{marginRight:2}}/> See Historical Data</MenuItem>
      </Paper>
    </ClickAwayListener>
  </Popper>
  {openEditor && 
    <ConcentrationEditor company={company} study={study} segment={segment} open={openEditor} onClose={handleClose} forceReload={forceReload}/>
    }
  {openHistory && 
  <CompanyCategoryHistory company={company} study={study} segment={segment} category={category} open={openHistory} onClose={handleClose}/>
  }
  </>
  )
};

export default ConcentrationActions;
