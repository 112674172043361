import { Typography, Grid, Stack } from '@mui/material';
import formatCurrency from '../../../../utils/formatCurrency'
import SegmentChip from '../../../chips/SegmentChip';
export default function DisplayCategory({segment, category, year}) {

    return (
        <Grid container spacing={6} sx={{width:1}}>
            <Grid item xs={1} sx={{marginTop:2}}>
                <SegmentChip segment={segment?.name}/>
            </Grid>
            <Grid item xs={5} sx={{marginTop:2.5}}>
                <Typography textAlign='left' fontWeight='bold' color='primary' variant='body1'> {category.name}</Typography>
            </Grid>
            <Grid item xs={2} sx={{marginTop:1}}>
                <Stack>
                    <Typography variant='body2' sx={{color:'#737980'}} fontWeight={'bold'}>
                        {formatCurrency(category.prev_year_revenue)}
                    </Typography>
                    <Typography variant='caption' sx={{color:'#737980'}}>
                        {year-1} 
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={2} sx={{marginTop:1}}>
                <Stack>
                    <Typography variant='body1' color='primary' fontWeight={'bold'}>
                        {formatCurrency(category.revenue)}
                    </Typography>
                    <Typography variant='caption' color='primary'>
                        {year} 
                    </Typography>
                </Stack> 
            </Grid>
            <Grid item xs={2} sx={{marginTop:1}}>
                <Stack>
                    <Typography variant='body1' color='secondary' fontWeight={'bold'}>
                        {category.year_over_year_change} %
                    </Typography>
                    <Typography variant='caption'color='secondary' >
                        +/-
                    </Typography>
                </Stack>
            </Grid>
        </Grid> 
    )
}