
import {Grid, Container } from '@mui/material'
import StudyStatus from './StudyStatus'
import Instructions from './Instructions'


 export default function StudyLanding({study}) {

    return (
        <Container>

        
        <Grid container sx={{marginTop:5}} spacing={4} >
            <Grid item xs={6}>
                <Instructions study={study}/>
            </Grid>
            <Grid item xs={6}>
                <StudyStatus study={study}/>
            </Grid>
        </Grid>
    </Container>
    )
 }