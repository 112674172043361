import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box, CircularProgress } from '@mui/material';
import axiosInstance from '../../../axiosConfig';
import { useSnackbar } from '../../../context/SnackbarContext';

const NoteEditor = ({ company, total, open, onClose }) => {
    const {openSnackbar} = useSnackbar();
    const [note, setNote] = useState(total.note);
    const [loading, setLoading] = useState(false);
    const handleNoteChange = (event) => {
        setNote(event.target.value);
    };

    const handleSave = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.put('company-totals/' + total.id, {note: note})
            console.log("submitted concentration update",response)
            openSnackbar('Successfully updated note for ' + company.name)
            setLoading(false);
        }
        catch (error) {
            console.error('Error updating concentration status:', error);
            openSnackbar('Failed to update note', 'error')
        }
        onClose();
    };

    const handleCancel = () => {
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleCancel} maxWidth="xs" fullWidth>
            <DialogTitle>Edit Note</DialogTitle>
            {loading && <Box sx={{width:1, display:'flex', justifyContent:'center', alignItems:'center', marginBottom:5}}> <CircularProgress/> </Box>}
            {(!loading) && <>
            <DialogContent >
                
                <TextField
                    autoFocus
                    multiline
                    rows={4}
                    fullWidth
                    value={note}
                    onChange={handleNoteChange}
                    label="Note"
                    sx={{marginTop:2}}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleSave} variant="contained" color="primary">
                    Save
                </Button>
            </DialogActions>
            </>}
        </Dialog>
    );
};

export default NoteEditor;