
import React, {useState, useEffect} from 'react';
import { CircularProgress, Paper, Switch, Grid, Stack, Typography, Box } from '@mui/material';

import PieChart from '../charts/basecharts/PieChart';

import useAPI from '../../hooks/useAPI';



function createDataset(records, field) {

    return (
            {labels: records.map(record => record.status), 
            datasets: [
                {
                    label: field,
                    data: records.map(record => record[field]),
                    backgroundColor: ['#4e73df', '#1cc88a', '#36b9cc', '#f6c23e'],
                    hoverBackgroundColor: ['#2e59d9', '#17a673', '#2c9faf', '#d6b45e'],
                    hoverBorderColor: "rgba(234, 236, 244, 1)",
                  }
                ]
            }
            )
}

export default function StudyStatus({study}) {
    const {data, error, loading} = useAPI('v1/studies/'+ study.id+'/status')
    const [statuses, setStatuses] = useState(null)
    const [sum, setSum] = useState(true);
    console.log(sum)
    
    useEffect(() => {
        if (data) {
            console.log("data from study status component", data.data)
            setStatuses(data.data)
            var chartData = createDataset(data.data, 'sum')
            console.log(chartData)
        }

    }, [data])
    return (
        <Paper elevation={3} sx={{backgroundColor:'#d3dceb', padding:2, maxHeight:600}}>
                <Grid container spacing={1}  justifyContent={"center"} alignItems={"center"}>
                    <Grid item xs={9}/>
                    <Grid item xs={2}>
                        <Stack direction={'horizontal'} spacing={1}>
                            <Typography variant='caption'>count</Typography>
                            <Switch 
                                value={sum}
                                onChange={(event,value) => {setSum(value)}}
                                inputProps={{ 'aria-label': 'controlled' }}
                                defaultChecked
                                />
                            <Typography variant='caption'>$</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                    {loading && <Box sx={{ display: 'flex', width:1, justifyContent:'center' }}><CircularProgress/></Box>}
                    {error && (!loading) && <Box sx={{ display: 'flex', width:1, justifyContent:'center' }}><Typography> Error loading data </Typography></Box>}
                    {statuses && 
                         <Box width={1}>
                            <Typography variant='h5' color='primary'> {sum?'Status by Market':'Status by Number of Companies'}</Typography>
                            <PieChart chartData={createDataset(statuses, sum?'sum':'count')}/>
                         </Box>
                    }
                       
                    </Grid>
                </Grid>
        </Paper>
   )
}
